import { Component } from '@angular/core';

@Component({
  selector: 'app-deliclub',
  templateUrl: './deliclub.component.html',
  styleUrls: ['./deliclub.component.scss']
})
export class DeliclubComponent {

}
