import { Component } from '@angular/core';

@Component({
  selector: 'app-clients-information',
  templateUrl: './clients-information.component.html',
  styleUrls: ['./clients-information.component.scss']
})
export class ClientsInformationComponent {

}
