<div id="column-slide" class="visible">
  <div id="logo-block-bg" class="logo-block-bg-full"></div>

  <app-header></app-header>

  <div class="clear"></div>

  <div id="slogan">
    нвестиции<br />как искусство
    <div class="slogan-small-comment">
      Лучшая инвестиционная компания<br />по версии WEALTH Navigator Awards 2023
      года
    </div>

    <div _ngcontent-ng-c170511141="" class="test-button-over" (click)="showAlert()">
      <a
        class="test-button"
        ><span _ngcontent-ng-c170511141="">ВАЖНОЕ ПРЕДУПРЕЖДЕНИЕ ДЛЯ КЛИЕНТОВ И ИНВЕСТОРОВ!</span></a
      >
    </div>
    <div
      class="alert-message"
      [style]="{
        height: isAlertShown ? '400px' : '0px',
        overflow: isAlertShown ? 'visible' : 'hidden',
        opacity: isAlertShown ? '1' : '0'
      }"
    >
      <p>
        <strong
          >Внимание! Обнаружена попытка мошенничества! <br />
          #12.03.2024</strong
        >
      </p>
      <p>
        Уважаемые клиенты, в сети интернет по адресу:
        <a href="">https://investfing.org</a> выявлен поддельный сайт,
        копирующий сайт нашей Компании!
      </p>
      <p>
        ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» не имеет отношения к данному сайту не
        оказывает через него никаких услуг, а сам сайт являются мошенническим,
        намеренно повторяющими внешний вид и частичное содержание официального
        сайта ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ».
      </p>
      <p>
        Кнопка «Регистрация/Авторизация» на поддельном сайте ведёт на сторонний
        ресурс <a href="">https://tradeinvestfing.org</a>, который не имеет
        отношения к ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» и не используется при
        обслуживании наших клиентов.
      </p>
      <p>
        ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» имеет только один официальный сайт в сети
        интернет для работы с СFD контрактами, расположенный по адресу
        <a href="https://cfd-generalinvest.ru">https://cfd-generalinvest.ru</a>
      </p>
      <p>
        ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» не использует системы переводов иностраных
        компаний(Совет ЕС принял 14-й пакет санкций против РФ) и не
        предоставляет личный счёт физического лица в смс варианте.
      </p>
      <p>
        В настоящее время сайт https://investfing.org уже заблокирован на
        оборудовании российских провайдеров. В случае обнаружения
        фальсифицированного сайта, копирующего дизайн официального сайта ООО
        Концерн «ДЖЕНЕРАЛ-ИНВЕСТ», пожалуйста, незамедлительно сообщить об этом
        можно на официальный адрес:
        <a href="mailto:info@generalinvest.cloud">info@generalinvest.cloud</a>
      </p>
      <p>
        Во избежание хищения принадлежащих вам денежных средств, предостерегаем
        вас от использования фейковых недостоверных сервисов в сети интернет, в
        частности, сайта <a href="">investfing.org.</a>
      </p>
    </div>
  </div>
  <div class="arrow-down"></div>
  <div id="content-slide-block" class=""></div>
  <div id="main-slide" class="movebg"></div>

  <div id="logo-block-bg-b" class="hidden"></div>
</div>

<div id="column" class="visible">
  <div class="company-history">
    <div class="col50">
      <div class="company-left">
        <div
          class="company-header absolute-header wow company-anim"
          data-wow-offset="200"
          style="animation-name: visible-scale; visibility: visible"
        >
          <span class="double-border"
            >Компания с историей и традициями <br /><a
              href="/history"
              class="company-anot-more"
              >Узнайте больше</a
            ></span
          >
        </div>
      </div>
    </div>
    <div class="col50">
      <div class="row50 company-anot-t">
        <div
          class="company-anot wow company-anim"
          data-wow-offset="200"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="company-anot-over">
            Аудиторское заключение за 2023 год
            <div>
              <a (click)="openModal()" class="company-anot-more"
                >Узнайте больше</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row50 company-anot-b">
        <div
          class="company-anot wow company-anim"
          data-wow-offset="200"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="company-anot-over">
            Лицензии Центрального банка России
            <div>
              <a href="/licenses" class="company-anot-more"
                >Посмотрите лицензии</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script>
    window.onload = function () {
      $(".single-item").slick({
        dots: false,
        prevArrow: '<div class="top-prev"></div>',
        nextArrow: '<div class="top-next"></div>',
        speed: 1000,
        fade: true,
        autoplay: false,
        autoplaySpeed: 6000,
      });
    };
  </script>

  <div class="single-item slick-initialized slick-slider">
    <div class="top-prev slick-arrow" style="display: block"></div>

    <div aria-live="polite" class="slick-list draggable">
      <div class="slick-track" role="listbox" style="opacity: 1; width: 4182px">
        <div
          class="company-news slick-slide slick-current slick-active"
          data-slick-index="0"
          aria-hidden="false"
          tabindex="-1"
          role="option"
          aria-describedby="slick-slide00"
          style="
            width: 1394px;
            position: relative;
            left: 0px;
            top: 0px;
            z-index: 999;
            opacity: 1;
          "
        >
          <div
            class="news-bg wow news-bg-anim"
            data-wow-offset="200"
            style="
              background: url('../../assets/main/5J9A5829.jpg') 30% 50% / 130%
                no-repeat;
              animation-name: move-bg;
              visibility: visible;
            "
          >
            <div
              class="company-news-header wow company-anim"
              data-wow-offset="200"
              style="animation-name: visible-scale; visibility: visible"
            >
              <div class="double-border-news">
                <span
                  >General Invest — лауреат премии WEALTH Navigator Awards
                  2023</span
                >
                <div>
                  <a href="/news" class="company-news-more" tabindex="0"
                    >Узнайте больше</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="company-news slick-slide"
          data-slick-index="1"
          aria-hidden="true"
          tabindex="-1"
          role="option"
          aria-describedby="slick-slide01"
          style="
            width: 1394px;
            position: relative;
            left: -1394px;
            top: 0px;
            z-index: 998;
            opacity: 0;
          "
        >
          <div
            class="news-bg wow news-bg-anim"
            data-wow-offset="200"
            style="
              background: url('/images/news/full/211212410713/53a985cf78d5.jpg')
                30% 50% / 130% no-repeat;
              animation-name: move-bg;
              visibility: visible;
            "
          >
            <div
              class="company-news-header wow company-anim"
              data-wow-offset="200"
              style="animation-name: visible-scale; visibility: visible"
            >
              <div class="double-border-news">
                <span
                  >General Invest названа лучшей инвестиционной компанией года
                  на премии WEALTH Navigator Awards 2022</span
                >
                <div>
                  <a href="/news/297" class="company-news-more" tabindex="-1"
                    >Узнайте больше</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="company-news slick-slide"
          data-slick-index="2"
          aria-hidden="true"
          tabindex="-1"
          role="option"
          aria-describedby="slick-slide02"
          style="
            width: 1394px;
            position: relative;
            left: -2788px;
            top: 0px;
            z-index: 998;
            opacity: 0;
          "
        >
          <div
            class="news-bg wow news-bg-anim"
            data-wow-offset="200"
            style="
              background: url('../../assets/press/moscow_1405.jpg') 30% 50% /
                130% no-repeat;
              animation-name: move-bg;
              visibility: visible;
            "
          >
            <div
              class="company-news-header wow company-anim"
              data-wow-offset="200"
              style="animation-name: visible-scale; visibility: visible"
            >
              <div class="double-border-news">
                <span
                  >General Invest вошла в список крупнейших брокерских компаний
                  России по версии КоммерсантЪ</span
                >
                <div>
                  <a href="/news/250" class="company-news-more" tabindex="-1"
                    >Узнайте больше</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="top-next slick-arrow" style="display: block"></div>
  </div>

  <div id="company-awards">
    <div
      id="col-test"
      class="wow up-anim"
      data-wow-offset="200"
      style="animation-name: slowmove-up; visibility: visible"
    >
      Какой<br />вы инвестор?
      <div><div id="risk-q">Определите свой риск-профиль</div></div>
      <div class="test-button-over">
        <a
          href="https://workspace.webmeta.finance"
          target="_blank"
          class="test-button"
          ><span>Перейти к анкете</span></a
        >
      </div>
    </div>
    <div id="col-awards">
      <span
        class="awards-header wow up-anim"
        style="animation-name: slowmove-up; visibility: visible"
        >Достижения</span
      >

      <div
        class="customized-scroll wow up-anim"
        style="animation-name: slowmove-up; visibility: visible"
      >
        <div class="scroll-wrapper scrollbar-rail" style="position: relative">
          <div
            class="scrollbar-rail scroll-content scroll-scrolly_visible"
            style="
              height: auto;
              margin-bottom: 0px;
              margin-right: 0px;
              max-height: 438px;
            "
          >
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_23.png') left
                  top no-repeat;
              "
            >
              <span>«Лучшая инвестиционная Компания года», 2023</span>
            </div>
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_w6.png') left
                  top no-repeat;
              "
            >
              <span
                >General Invest вошла в список крупнейших брокерских компаний
                России по версии КоммерсантЪ, 2022, 2021, 2023 годы</span
              >
            </div>
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_w8.png') left
                  top no-repeat;
              "
            >
              <span>«Лучшая инвестиционная Компания года», 2022</span>
            </div>
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_w4.png') left
                  top no-repeat;
              "
            >
              <span
                >3-е место в 2019 г., 4-е в 2018 г., 6-е в 2017 г., среди
                инвестиционных компаний России</span
              >
            </div>
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_w2.png') left
                  top no-repeat;
              "
            >
              <span
                >Лауреат премии «Лучшая инвестиционная компания», 2016, 2018,
                2021. Генеральный директор лауреат премии «Инвестиционный
                консультант года», 2021</span
              >
            </div>
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_w1.png') left
                  top no-repeat;
              "
            >
              <span
                >Компания года в сфере Wealth Management, премия «Финансовая
                элита России», 2016 год</span
              >
            </div>
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_w1.png') left
                  top no-repeat;
              "
            >
              <span
                >Топ-10 лучших управляющих благосостоянием по версии Forbes,
                2015 год</span
              >
            </div>
            <div
              class="awards-single"
              style="
                background: url('../../assets/awards/logo_awards_w5.png') left
                  top no-repeat;
              "
            >
              <span
                >Лауреат премии в номинации «За привлечение иностранных
                инвестиций в Россию», 2011 год</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="company-map">
    <div class="dragscroll">
      <div class="map-img"></div>
    </div>
    <div
      id="index-map-header"
      class="wow up-anim"
      data-wow-offset="200"
      style="animation-name: slowmove-up; visibility: visible"
    >
      География бизнеса
    </div>
    <div id="map-mobpadding">
      <div
        class="map-info-block wow company-anim"
        data-wow-offset="200"
        style="animation-name: visible-scale; visibility: visible"
      >
        <span class="double-border-map"
          >Мы хотим быть ближе к нашим клиентам и постоянно расширяем
          региональную сеть <br /><a href="/contacts" class="company-anot-more"
            >Узнайте больше</a
          ></span
        >
      </div>
    </div>
  </div>

  <div id="company-m-news">
    <div id="col-analitic">
      <div
        id="analitic-header"
        class="wow up-anim"
        data-wow-offset="200"
        style="animation-name: slowmove-up; visibility: visible"
      >
        Аналитика
      </div>
      <div
        class="wow up-anim"
        data-wow-offset="200"
        style="animation-name: slowmove-up; visibility: visible"
      >
        <div class="analitic-item">
          30.05.2024
          <a>Недооценен ли российский рынок</a>
        </div>

        <div class="analitic-item">
          29.05.2024
          <a>IPO IVA Technologies</a>
        </div>

        <div class="analitic-item">
          16.05.2024
          <a>Рынок меди: аналитический обзор</a>
        </div>

        <div class="centermobblock">
          <div class="transp-button-over">
            <a (click)="openModal()" class="transp-button"
              ><span>Оставить заявку</span></a
            >
          </div>
        </div>
      </div>
    </div>
    <div id="col-news">
      <div
        id="news-header"
        class="wow up-anim"
        data-wow-offset="200"
        style="animation-name: slowmove-up; visibility: visible"
      >
        Новости
      </div>

      <div
        class="news-main-item wow up-anim"
        style="
          background: url('../../assets/news/7.jpg') 0% 0% / cover no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        data-wow-offset="200"
      >
        <div class="news-main-item-back"></div>
        <div class="news-main-item-header">
          <div>31.05.2024</div>
          <a
            >Внесение изменений во внутренние документы ООО Концерн
            «ДЖЕНЕРАЛ-ИНВЕСТ»</a
          >
        </div>
      </div>

      <div
        class="news-main-item wow up-anim"
        style="
          background: url('../../assets/news/17.jpg') 0% 0% / cover no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        data-wow-offset="200"
      >
        <div class="news-main-item-back"></div>
        <div class="news-main-item-header">
          <div>31.05.2024</div>
          <a>Хранение USD на брокерских счетах</a>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
