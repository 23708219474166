import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { LicenseModalComponent } from 'src/app/UI/license-modal/license-modal.component';
import { License2ModalComponent } from 'src/app/UI/license2-modal/license2-modal.component';
import { ModalService } from 'src/app/http-service/modal.service';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
})
export class LicensesComponent {
  @ViewChild('slickTrack', { static: true }) slickTrack!: ElementRef;

  public indexYear: number = 0;

  constructor(private renderer: Renderer2, private modalService: ModalService) {}

  slideRight() {
    this.indexYear++;
    if (this.indexYear > 5) {
      this.indexYear = 0;
    }
    this.slide();
  }
  slideLeft() {
    this.indexYear--;
    if (this.indexYear < 0) {
      this.indexYear = 9;
    }
    this.slide();
  }

  slide() {
    this.renderer.setStyle(
      this.slickTrack.nativeElement,
      'left',
      `-${this.indexYear * 321}px`
    );
  }

  openModal1() {
    this.modalService.openModal(LicenseModalComponent); 
  }
  openModal2() {
    this.modalService.openModal(License2ModalComponent); 
  }
}
