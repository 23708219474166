import { Component } from '@angular/core';

@Component({
  selector: 'app-press-news',
  templateUrl: './press-news.component.html',
  styleUrls: ['./press-news.component.scss']
})
export class PressNewsComponent {

}
