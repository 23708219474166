import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AssetManagementComponent } from './services/asset-management/asset-management.component';
import { BrokerageServicesComponent } from './services/brokerage-services/brokerage-services.component';
import { DepositaryComponent } from './services/depositary/depositary.component';
import { AdvisoryComponent } from './services/advisory/advisory.component';
import { ClientsInformationComponent } from './services/clients-information/clients-information.component';
import { DeliclubComponent } from './services/deliclub/deliclub.component';
import { AboutComponent } from './about/about.component';
import { HistoryComponent } from './about/history/history.component';
import { TeamComponent } from './about/team/team.component';
import { HowToBecomeAClientComponent } from './about/how-to-become-a-client/how-to-become-a-client.component';
import { AffiliateProgramsComponent } from './about/affiliate-programs/affiliate-programs.component';
import { LicensesComponent } from './about/licenses/licenses.component';
import { SocialResponsibilityComponent } from './about/social-responsibility/social-responsibility.component';
import { PressComponent } from './press/press.component';
import { ContactsComponent } from './contacts/contacts.component';
import { NewsComponent } from './press/news/news.component';
import { EventsComponent } from './press/events/events.component';
import { PressNewsComponent } from './press/press-news/press-news.component';
import { ContactsForMediaComponent } from './press/contacts-for-media/contacts-for-media.component';
import { CareerComponent } from './about/career/career.component';

const routes: Routes = [
  {path: '', component:MainComponent},
  {path: 'asset_management', component:AssetManagementComponent},
  {path: 'brokerage_services', component:BrokerageServicesComponent},
  {path: 'depositary', component:DepositaryComponent},
  {path: 'advisory', component:AdvisoryComponent},
  {path: 'clients-information', component:ClientsInformationComponent},
  {path: 'deliclub', component:DeliclubComponent},
  {path: 'about', component:AboutComponent},
  {path: 'history', component:HistoryComponent},
  {path: 'team', component:TeamComponent},
  {path: 'how_to_become_a_client', component:HowToBecomeAClientComponent},
  {path: 'affiliate_programs', component:AffiliateProgramsComponent},
  {path: 'licenses', component:LicensesComponent},
  {path: 'social_responsibility', component:SocialResponsibilityComponent},
  {path: 'press', component:PressComponent},
  {path: 'contacts', component:ContactsComponent},
  {path: 'news', component:NewsComponent},
  {path: 'events', component:EventsComponent},
  {path: 'press_news', component:PressNewsComponent},
  {path: 'contacts_for_media', component:ContactsForMediaComponent},
  {path: 'career', component:CareerComponent},

  { path: '**', component: MainComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
