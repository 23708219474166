<div id="column-slide" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header-team">
    <h1>Команда</h1>
    Сотрудники General Invest — это признанные в индустрии профессионалы из
    ведущих российских и международных компаний, таких как Citibank, УК
    «Ренессанс Управление Инвестициями», Sberbank CIB, ИК «Тройка Диалог», УК
    «Атон», УК «ПРОМСВЯЗЬ» и других.
  </div>
  <div id="team-slide"></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about">О компании</a>

    &nbsp;/&nbsp;Команда
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="about-points section-t">
    <div class="team-menu" id="team-menu">
      <div class="personal-block-marg">
        <div id="item-menu-leadership" class="selected">
          <span>Руководство</span>
        </div>
        <div id="item-menu-team"><span>Инвестиционная команда</span></div>
        <!--<div id="item-menu-partner"><span>Партнеры</span></div>-->
        <div id="item-menu-region"><span>Региональная сеть</span></div>
      </div>
    </div>

    <div id="team-group">
      <div class="box-t openblockteam" id="leadership-block">
        <div class="personal-block-marg">
          <div class="multiple-item-grid">
            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=63"
                    ><img src="../../../assets/team/khenkin.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Александр Хенкин</div>
                <div class="team-position">Генеральный директор</div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=29"
                    ><img src="../../../assets/team/a-ivanova.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Анна Иванова</div>
                <div class="team-position">
                  Заместитель генерального директора по стратегическому
                  развитию, руководитель региональной сети продаж
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=16"
                    ><img src="../../../assets/team/merudzan.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Меружан Дадалян</div>
                <div class="team-position">
                  Заместитель генерального директора по управлению частным
                  капиталом и инвестициями
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=71"
                    ><img src="../../../assets/team/i-beyret.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Ирина Бейрет</div>
                <div class="team-position">
                  Директор по развитию партнерской сети
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=6"
                    ><img src="../../../assets/team/d-rotachev.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Дмитрий Ротачев</div>
                <div class="team-position">
                  Руководитель отдела по работе с клиентами, управляющий
                  директор
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=47"
                    ><img src="../../../assets/team/gubin.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Алексей Губин</div>
                <div class="team-position">Директор по инвестициям</div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=64"
                    ><img src="../../../assets/team/rodionov.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Павел Родионов</div>
                <div class="team-position">
                  Директор брокерских продуктов и сервисов
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=40"
                    ><img src="../../../assets/team/rybakov.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Александр Рыбаков</div>
                <div class="team-position">Финансовый директор</div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=65"
                    ><img src="../../../assets/team/v-guryanov.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Владимир Гурьянов</div>
                <div class="team-position">
                  Заместитель Генерального директора по развитию бизнеса и
                  стратегии
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=66"
                    ><img src="../../../assets/team/y-griberman.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Юлия Гриберман</div>
                <div class="team-position">
                  Директор по связям с общественностью и маркетингу
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=67"
                    ><img src="../../../assets/team/a-isaeva.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Анна Исаева</div>
                <div class="team-position">Главный операционный директор</div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=68"
                    ><img src="../../../assets/team/l-zhuravlyova.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Лилия Журавлёва</div>
                <div class="team-position">
                  Директор по информационным технологиям
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=61"
                    ><img src="../../../assets/team/t-fedorova.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Татьяна Федорова</div>
                <div class="team-position">Главный бухгалтер</div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=69"
                    ><img src="../../../assets/team/a-gavryushina.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Александра Гаврюшина</div>
                <div class="team-position">
                  Руководитель юридического управления
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=70"
                    ><img src="../../../assets/team/m-novikova.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Мария Новикова</div>
                <div class="team-position">
                  Руководитель по подбору и адаптации персонала
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-t" id="team-block">
        <div class="personal-block-marg">
          <div class="multiple-item-grid">
            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=47"
                    ><img src="../../../assets/team/gubin.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Алексей Губин</div>
                <div class="team-position">Директор по инвестициям</div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=64"
                    ><img src="../../../assets/team/rodionov.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Павел Родионов</div>
                <div class="team-position">
                  Директор брокерских продуктов и сервисов
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=51"
                    ><img src="../../../assets/team/m-smirnov.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Михаил Смирнов</div>
                <div class="team-position">
                  Портфельный управляющий по глобальным рынкам, заместитель
                  директора по инвестициям и управлению
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=55"
                    ><img src="../../../assets/team/d-rezepov.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Димитрий Резепов</div>
                <div class="team-position">
                  Портфельный управляющий, заместитель директора по инвестициям
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=52"
                    ><img src="../../../assets/team/zorina.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Ксения Зорина</div>
                <div class="team-position">
                  Руководитель управления структурных продуктов
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=46"
                    ><img src="../../../assets/team/simonova.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Татьяна Симонова</div>
                <div class="team-position">
                  Портфельный управляющий по российским акциям
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-t" id="region-block">
        <div class="personal-block-marg">
          <div class="multiple-item-grid">
            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=53"
                    ><img src="../../../assets/team/zhestkov01.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Александр Жестков</div>
                <div class="team-position">
                  Региональный директор в Санкт-Петербурге
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=39"
                    ><img src="../../../assets/team/bobryashov.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Владимир Бобряшов</div>
                <div class="team-position">
                  Региональный директор в Екатеринбурге
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=62"
                    ><img src="../../../assets/team/sotnikov-i.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Иван Сотников</div>
                <div class="team-position">Региональный директор по Сибири</div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=44"
                    ><img src="../../../assets/team/plahotnuk.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Татьяна Плахотнюк</div>
                <div class="team-position">
                  Региональный директор в Краснодаре
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=73"
                    ><img src="../../../assets/team/d-gribenyuk.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Даниил Грибенюк</div>
                <div class="team-position">
                  Управляющий директор направления инвестиционного развития в г.
                  Самаре
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=72"
                    ><img src="../../../assets/team/s-garaeva.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Светлана Гараева</div>
                <div class="team-position">
                  Управляющий директор направления инвестиционного развития по
                  Республике Татарстан
                </div>
              </div>
            </div>

            <div class="team-item">
              <div class="team-item-photo-block">
                <div class="team-item-photo">
                  <a href="team?manager=74"
                    ><img src="../../../assets/team/s-bekenova.png" alt=""
                  /></a>
                </div>
              </div>
              <div class="team-item-info">
                <div class="team-name">Сабира Бекенова</div>
                <div class="team-position">
                  Управляющий директор по работе с клиентами в Казани
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-form></app-form>
  <app-footer></app-footer>
</div>
