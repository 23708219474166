import { Component } from '@angular/core';

@Component({
  selector: 'app-license2-modal',
  templateUrl: './license2-modal.component.html',
  styleUrls: ['./license2-modal.component.scss']
})
export class License2ModalComponent {

}
