import { Component } from '@angular/core';

@Component({
  selector: 'app-contacts-for-media',
  templateUrl: './contacts-for-media.component.html',
  styleUrls: ['./contacts-for-media.component.scss']
})
export class ContactsForMediaComponent {

}
