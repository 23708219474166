import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private http: HttpClient) {}

  sendUserInfo(name: string, email: string, phone: string): Observable<any> {
    return this.http.post('https://cfd-generalinvest.ru:5960/api/user-info', {
      name: name,
      email: email,
      phone: phone,
    });
  }
}
