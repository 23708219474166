<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Инвестиционное консультирование</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/advisory/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Услуги &nbsp;/&nbsp;Инвестиционное консультирование
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <script>
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: false,
        speed: 800,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="mainnews-list">
    12 февраля 2019 года Центральный банк Российской Федерации (Банк России)
    включил ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» в единый реестр инвестиционных
    советников.
    <br /><br />28 февраля 2019 года ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» стало членом
    саморегулируемой организации в сфере финансового рынка в отношении
    деятельности инвестиционных советников (НАУФОР). <br /><br />ООО Концерн
    «ДЖЕНЕРАЛ-ИНВЕСТ» осуществляет инвестиционное консультирование физических и
    юридических лиц, которые разбираются в финансовых рынках, предпочитая
    самостоятельно принимать решения по сделкам. Мы подбираем эффективные
    решения и даем практические рекомендации по сохранению и приумножению
    капитала. Инвестиционные консультации наших специалистов помогут вам принять
    окончательное решение по наполнению портфеля.
  </div>
  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="200">
      Инвестиционное консультирование включает
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.2s"
      data-wow-offset="100"
      style="
        animation-delay: 0.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>1.</div></div>
      <div class="advantage-info">
        Обеспечение доступа к аналитическим материалам
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.4s"
      data-wow-offset="100"
      style="
        animation-delay: 0.4s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>2.</div></div>
      <div class="advantage-info">
        Информирование клиента обо всех последних событиях рынка ценных бумаг и
        о финансовых продуктах
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>3.</div></div>
      <div class="advantage-info">
        Информирование клиента обо всех последних новостях, имеющих отношение к
        торговой позиции клиента или к его потенциальным инвестиционным
        интересам
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.8s"
      data-wow-offset="100"
      style="
        animation-delay: 0.8s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block"><div>4.</div></div>
      <div class="advantage-info">
        Разработка персональной инвестиционной стратегии и/или оперативная
        разработка инвестиционных решений в интересах клиента с учетом
        инвестиционного профиля клиента
      </div>
    </div>
  </div>

  <div class="about-points">
    <div class="strategy-header">Документы</div>
    <div class="brokerage-block-marg">
      <div class="multiple-item slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
          <div
            class="slick-track"
            role="listbox"
            style="width: 742px; left: 0px; opacity: 1"
          >
            <div
              class="slick-slide slick-current slick-active"
              data-slick-index="0"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide00"
              style="width: 371px"
            >
              <div class="findocs-block doc-link-s">
                Декларация о рисках, связанных с осуществлением операций на
                рынке ценных бумаг
              </div>
              <div class="findocs-info">
                Актуально с 21.11.2019 по настоящее время <br />Дата размещения
                21.11.2019 <br /><br /><a
                  href="/docs/declaracia_o_riskah.pdf"
                  class="button-link-doc"
                  tabindex="0"
                  >Скачать</a
                >
              </div>
            </div>
            <div
              class="slick-slide slick-active"
              data-slick-index="1"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide01"
              style="width: 371px"
            >
              <div class="findocs-block doc-link-s">
                Положения об определении инвестиционного профиля Клиента при
                осуществлении деятельности по инвестиционному консультированию
              </div>
              <div class="findocs-info">
                Актуально с 11.01.2024 по настоящее время <br />Дата размещения
                29.12.2023 <br /><br /><a
                  href="/docs/poryadok_opredelenia_profilya_0124_ik_01.pdf"
                  class="button-link-doc"
                  tabindex="0"
                  >Скачать</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-points">
    <div class="dactive-comment">
      * Уважаемые Клиенты, уведомляем вас о том, что Вы вправе подать ООО
      Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» заявление об исключении Вас из реестра лиц,
      признанных квалифицированными инвесторами. В случае подачи такого
      заявления Вы утратите возможность, пользуясь услугами ООО Концерн
      «ДЖЕНЕРАЛ-ИНВЕСТ», приобретать ценные бумаги и заключать договоры,
      являющиеся производными финансовыми инструментами, в отношении которых Вы
      были признаны ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» квалифицированным инвестором.
      <br />Заявление об исключении из реестра лиц, признанных ООО Концерн
      «ДЖЕНЕРАЛ-ИНВЕСТ» квалифицированными инвесторами, может быть направлено на
      бумажном носителе (лично или по почте) или в иной форме и иным способом с
      использованием средств связи, предусмотренных для обмена сообщениями
      соответствующим договором, заключенным с клиентом (за исключением обмена
      сообщениями посредством ИТС QUIK). <br />Форма направления заявления об
      исключении из реестра лиц, признанных квалифицированными инвесторами,
      приведена в приложении к Регламенту признания лиц квалифицированными
      инвесторами ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ».
    </div>
  </div>

  <div class="about-advantage">
    <a href="/advisory_projects" class="awards-header"
      >Инвестиционное консультирование. Проекты документов</a
    >
    <br /><br /><br /><br />

    <a href="/advisory_archive" class="awards-header"
      >Инвестиционное консультирование (архив)</a
    >
  </div>

  <app-footer></app-footer>
</div>
