import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/http-service/form.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  myForm: FormGroup;

  formSended:boolean = false;

  constructor(private fb: FormBuilder, private formService: FormService) {
    this.myForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?[0-9]{10}$/)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    
  }

  get name() {
    return this.myForm.get('name')?.value;
  }

  get phone() {
    return this.myForm.get('phone')?.value;
  }

  get email() {
    return this.myForm.get('email')?.value;
  }

  sendForm() {
    if(!this.myForm.invalid) {
      this.formService.sendUserInfo(this.name, this.email, this.phone).subscribe((res) => {
        if (res) {
          this.formSended = true;
          this.myForm.reset();
        }
      });
    }
  }
}
