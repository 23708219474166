<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class="logo-block-bg-full"></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Лицензии</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/licenses/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about">О компании</a>

    &nbsp;/&nbsp;Лицензии
  </div>

  <div id="logo-block-bg-b" class="hidden"></div>
</div>

<div id="column" class="visible">
  <script>
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: false,
        autoplaySpeed: 6000,
        speed: 1200,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="quote-block-gb">
    <div id="team-group">
      <div id="team-block" style="opacity: 1; position: relative; top: 0">
        <div class="licenses-block-marg">
          <div class="multiple-item slick-initialized slick-slider">

            <div aria-live="polite" class="slick-list draggable">
              <div
                class="slick-track"
                role="listbox"
                style="opacity: 1; width: 3910px; left: 0; transition: all .2s ease-in-out;"
                #slickTrack
              >

                <div
                  class="team-item slick-slide slick-current slick-active"
                  data-slick-index="0"
                  aria-hidden="false"
                  tabindex="-1"
                  role="option"
                  aria-describedby="slick-slide00"
                  style="width: 321px"
                >
                  <div class="licenses-item-block" (click)="openModal1()">
                    <div class="licenses-over">
                      <a tabindex="0"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление депозитарной деятельности
                    </div>
                    <br />Актуально с 21.06.2011 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
                <div
                  class="team-item slick-slide slick-active"
                  data-slick-index="1"
                  aria-hidden="false"
                  tabindex="-1"
                  role="option"
                  aria-describedby="slick-slide01"
                  style="width: 321px"
                >
                  <div class="licenses-item-block" (click)="openModal2()">
                    <div class="licenses-over">
                      <a tabindex="0"
                        ><img src="../../../assets/licenses/license.jpg" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="team-item-info">
                    <div class="team-name">
                      Лицензия на осуществление деятельности по управлению
                      ценными бумагами
                    </div>
                    <br />Актуально с 10.11.2009 по настоящее время<br />Дата
                    размещения 13.05.2016
                  </div>
                </div>
               
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>

  <app-form></app-form>

  <app-footer></app-footer>
</div>
