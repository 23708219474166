<div id="footer">
  <div class="footer-contact-block mobfooter">
    <div id="phone-footer">8 (495) 727-00-77</div>
    <a routerLink="#dialog" class="popup-form">заказать звонок</a>
    <div style="margin-top: 8px">
      <a routerLink="/appeal">направить обращение в компанию</a>
    </div>
    <div id="lang-column">
      <div id="lang-footer">
        <a routerLink="#">Карта сайта</a>
        <div><span>RU</span> / <a routerLink="/eng/">EN</a></div>
      </div>
      <div class="facebook-footer">
        <a
          rel="nofollow"
          routerLink="https://play.google.com/store/apps/details?id=ru.gi.invest"
          class="googleplay"
          target="_blank"
        ></a>
        <a
          rel="nofollow"
          routerLink="https://itunes.apple.com/ru/app/general-invest/id1450387667"
          class="appstore"
          target="_blank"
        ></a>

        <a
          rel="nofollow"
          routerLink="https://t.me/generalinvest"
          class="telegram"
          target="_blank"
        ></a>
        <a
          rel="nofollow"
          routerLink="https://www.youtube.com/c/GeneralInvest_ru"
          class="youtube"
          target="_blank"
        ></a>
      </div>
    </div>
  </div>
  <div id="footer-menu">
    <div class="footer-contact-block">
      <div style="margin-top: 8px">
        <a href="https://t.me/InfoGeneralinvest" target="_blank">направить обращение в компанию</a>
      </div>

      <div class="facebook-footer">
        <a
          rel="nofollow"
          routerLink="https://play.google.com/store/apps/details?id=ru.gi.invest"
          class="googleplay"
          target="_blank"
        ></a>
        <a
          rel="nofollow"
          routerLink="https://itunes.apple.com/ru/app/general-invest/id1450387667"
          class="appstore"
          target="_blank"
        ></a>

        <a
          rel="dofollow"
          routerLink="https://t.me/generalinvest"
          class="telegram"
          target="_blank"
        ></a>
        <a
          rel="nofollow"
          routerLink="https://www.youtube.com/c/GeneralInvest_ru"
          class="youtube"
          target="_blank"
        ></a>
      </div>
      <div id="made">Дизайн A M I O</div>
    </div>
    <div class="footer-contact-block">
      <div class="footer-header">Контакты</div>
      <div id="footer-adress">
        Москва, 107031,<br />улица Петровка,<br />дом 7
      </div>
      <a routerLink="/contacts">Как добраться?</a>
    </div>

    <!-- <div class="footer-menu-block">
      <div class="footer-header">Услуги</div>

      <a routerLink="/asset_management">Доверительное управление</a>

      <a routerLink="/brokerage_services">Брокерское обслуживание</a>

      <a routerLink="/depositary">Депозитарное обслуживание</a>

      <a routerLink="/advisory">Инвестиционное консультирование</a>

      <a routerLink="/analytics">Аналитика</a>

      <a routerLink="/clients-information">Информация для клиентов</a>

      <a routerLink="/deliclub">Деликлуб</a>
    </div> -->
    <div class="footer-menu-block">
      <div class="footer-header">О компании</div>

      <a routerLink="/history">История компании</a>

      <a routerLink="/team">Команда</a>

      <a routerLink="/how_to_become_a_client">Как стать клиентом</a>

      <a routerLink="/affiliate_programs">Как стать партнером</a>

      <a routerLink="/licenses">Лицензии</a>

      <a routerLink="/information_disclosure">Раскрытие информации</a>

      <a routerLink="/career">Карьера</a>

      <a routerLink="/social_responsibility">Благотворительность</a>
    </div>
    <div class="footer-menu-block">
      <div class="footer-header">Пресс-центр</div>

      <a routerLink="/news">Новости</a>

      <a routerLink="/events">Мероприятия</a>

      <a routerLink="/press_news">Публикации в СМИ</a>

      <a routerLink="/contacts_for_media">Контакты для СМИ</a>
    </div>

    <div class="footer-menu-block block-cb">
      <div class="footer-header">ЦБ РФ</div>
      Отсканируйте QR-код для перехода в справочник финансовых организаций
    </div>
  </div>
  <div id="footer-disclaimer">
    Лицензии профессионального участника рынка ценных бумаг Общества с
    ограниченной ответственностью Концерн «ДЖЕНЕРАЛ-ИНВЕСТ»: на осуществление
    брокерской деятельности № 177-12660-100000 от 10 ноября 2009 г.; на
    осуществление дилерской деятельности № 177-12665-010000 от 10 ноября 2009
    г.; на осуществление деятельности по управлению ценными бумагами №
    177-12670-001000 от 10 ноября 2009 г.; на осуществление депозитарной
    деятельности № 177-13612-000100 от 21 июня 2011г.
    <br /><br />ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» внесено в Единый реестр
    инвестиционных советников Банка России за номером 29. <br /><br />Размер
    инвестиционного портфеля может увеличиваться и уменьшаться, результаты
    инвестирования в прошлом не определяют доходы в будущем, государство не
    гарантирует доходность инвестиций в ценные бумаги. Совершение сделок на
    рынке ценных бумаг является рискованным мероприятием, участие в котором
    может привести к потере всех или части инвестированных средств. Прежде чем
    начать инвестировать в рынок ценных бумаг, ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ»
    рекомендует внимательно рассмотреть вопрос о том, являются ли риски,
    возникающие при совершении сделок с ценными бумагами, приемлемыми для
    инвестора с учетом его инвестиционных целей и финансовых возможностей.
    <br /><br />Ознакомиться с условиями соответствующих договоров, а также
    получить иную информацию, которая должна быть предоставлена в соответствии с
    федеральным законом от 13.03.2006 N 38-ФЗ «О рекламе» и иными нормативными
    правовыми актами Российской Федерации можно по адресу: Российская Федерация,
    Москва, ул. Петровка, д. 7, или по телефону +7 (495) 727-00-77.
    <br /><br />Воспроизведение материалов возможно только с указанием ссылки на
    сайт www.generalinvest.ru. <br /><br />© Concern General Invest, 2009-
    <script type="text/javascript">
      var mdate = new Date();
      document.write(mdate.getFullYear());
    </script>
    2024
  </div>
</div>
