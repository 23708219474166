<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Информация для клиентов</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/clients-information/page-bg-press\ \(1\).jpg')
        no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Услуги &nbsp;/&nbsp;Информация для клиентов
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <style>
    .findocs-block.inline {
      margin-top: 20px;
    }
    .findocs-block.inline a {
      text-decoration: none;
      color: #000;
    }
  </style>

  <script>
    window.onload = function () {
      $(".multiple-item").slick({
        autoplay: false,
        speed: 800,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    };
  </script>

  <div class="quote-block-gb">
    <div class="brokerage-block-marg">
      <div class="multiple-item slick-initialized slick-slider">
        <div aria-live="polite" class="slick-list draggable">
          <div
            class="slick-track"
            role="listbox"
            style="width: 1113px; left: 0px; opacity: 1"
          >
            <div
              class="slick-slide slick-current slick-active"
              data-slick-index="0"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide00"
              style="width: 371px"
            >
              <div class="findocs-block doc-link-s">
                Налог на доходы физических лиц от операций с иностранными
                акциями и депозитарными расписками
              </div>
              <div class="findocs-info">
                <a href="/ndfl" class="button-link-doc" tabindex="0"
                  >Подробнее</a
                >
              </div>
            </div>
            <div
              class="slick-slide slick-active"
              data-slick-index="1"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide01"
              style="width: 371px"
            >
              <div class="findocs-block doc-link-s">
                Рекомендации клиентам по соблюдению мер информационной
                безопасности для защиты информации от воздействия вредоносных
                кодов
              </div>
              <div class="findocs-info">
                <a
                  href="/docs/recommendations_to_clients.pdf"
                  class="button-link-doc"
                  tabindex="0"
                  >Скачать</a
                >
              </div>
            </div>
            <div
              class="slick-slide slick-active"
              data-slick-index="2"
              aria-hidden="false"
              tabindex="-1"
              role="option"
              aria-describedby="slick-slide02"
              style="width: 371px"
            >
              <div class="findocs-block doc-link-s">
                Информация для ознакомления получателей финансовых услуг
              </div>
              <div class="findocs-info">
                Актуально с 13.04.2023 <br /><br /><a
                  href="/docs/information_for_reference_0423.pdf"
                  class="button-link-doc"
                  tabindex="0"
                  >Скачать</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br />
    <div class="section-header">
      Информация о финансовых инструментах и связанных с ними рисках
    </div>
    <div class="brokerage-block-marg">
      <div class="multiple-item-grid">
        <div class="findocs-block inline">
          <a
            href="/docs/obyknovennaya-akciya-rossijskogo-akcionernogo-obshchestva.pdf"
            >Обыкновенная акция российского акционерного общества</a
          >
        </div>
        <div class="findocs-block inline">
          <a
            href="/docs/privilegirovannaya-akciya-rossijskogo-akcionernogo-obshchestva.pdf"
            >Привилегированная акция российского акционерного общества</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/fyuchersnyj-dogovor.pdf"
            >Фьючерсный договор (контракт)</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/obligaciya-rossijskogo-emitenta.pdf"
            >Облигация российского эмитента</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/obligaciya-federalnogo-zajma-dlya-fizicheskih-lic.pdf"
            >Облигация федерального займа для физических лиц (ОФЗ-н)</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/inostrannaya-depozitarnaya-raspiska-na-akcii.pdf"
            >Иностранная депозитарная расписка на акции</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/subordinirovannaya-obligaciya-rossijskogo-emitenta.pdf"
            >Субординированная облигация российского эмитента</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/strukturnaya-obligaciya-rossijskogo-emitenta.pdf"
            >Структурная облигация российского эмитента</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/opcionnyj-dogovor.pdf">Опционный договор</a>
        </div>
        <div class="findocs-block inline">
          <a
            href="/docs/obligaciya-rossijskogo-emitenta-so-strukturnym-dohodom.pdf"
            >Облигация российского эмитента со структурным доходом</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/investicionnyj-paj-paevogo-investicionnogo-fonda.pdf"
            >Инвестиционный пай паевого инвестиционного фонда (кроме биржевого
            паевого инвестиционного фонда)</a
          >
        </div>
        <div class="findocs-block inline">
          <a
            href="/docs/investicionnyj-paj-rossijskogo-birzhevogo-paevogo-investicionnogo-fonda.pdf"
            >Инвестиционный пай российского биржевого паевого инвестиционного
            фонда</a
          >
        </div>
        <div class="findocs-block inline">
          <a href="/docs/akciya-paj-etf.pdf">Акция (пай) ETFБ</a>
        </div>
      </div>
    </div>

    <br /><br /><br />
    <div class="section-header">
      Информирование об использовании брокером в своих интересах денежных
      средств клиентов.<sup>1</sup>
    </div>
    <br />В соответствии со статьей 5 Регламента брокерского обслуживания ООО
    Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» (далее – Брокер) имеет право использовать в своих
    интересах денежные средства клиента, гарантируя при этом исполнение
    поручений клиента за счет указанных денежных средств. Клиент вправе
    направить Брокеру заявление об отказе от предоставления брокеру права
    использовать в своих интересах денежные средства клиента (заявление об
    отказе). Заявление может быть направлено путем предоставления оригинала
    заявления с собственноручной подписью на бумажном носителе в офисе Брокера
    по адресу местонахождения Брокера. В случае направления заявления об отказе
    Клиент обязан предпринять действия, направленные на заключение нового
    соглашения об оказании брокерских услуг, в рамках которого Клиентом не
    предоставлено право использования денежных средств Брокеру. Брокер
    уведомляет Клиента о последствиях направления заявления об отказе, а именно
    о недоступности Клиенту оказания услуг по совершению необеспеченных сделок;
    договоров репо, за исключением договоров репо, стороной по которым является
    лицо, осуществляющее функции центрального контрагента, или Брокер;
    внебиржевых сделок с расчетами в иностранной валюте; договоров, являющихся
    производными финансовыми инструментами, за исключением договоров,
    предусматривающих право Клиента или Брокера, действующего за его счет,
    требовать от другой стороны передачи ценных бумаг, валюты либо требовать
    уплаты денежных сумм в зависимости от изменения цен на товары, ценные
    бумаги, изменения курса соответствующей валюты, величины процентных ставок
    или от изменения значений, рассчитываемых на основании одного или
    совокупности указанных показателей, и обязанность такого Клиента или
    Брокера, действующего за его счет, уплатить другой стороне твердую денежную
    сумму.

    <br /><br /><br /><br />
    <div class="section-header">Информация о конфликте интересов</div>
    <br />Настоящим Общество с ограниченной ответственностью Компания
    «ДЖЕНЕРАЛ-ИНВЕСТ» (далее – Компания) информирует клиентов о возможном
    конфликте интересов, связанном с предложением к приобретению клиентами
    Компании ценных бумаг, эмитентом которых является ПАО «Каршеринг Руссия».
    ПАО «Каршеринг Руссия» является компанией, входящей в группу лиц и
    аффилированной с ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ». Инвестирование в ценные
    бумаги ПАО «Каршеринг Руссия» может повлечь возникновение конфликта
    интересов между ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» и его клиентами.
    <br /><br />Для целей предотвращения реализации конфликта интересов и
    управления конфликтом интересов предприняты меры по контролю за совершением
    лицами, действующими от имени Компании, действий, связанных с ценными
    бумагами эмитента; информация об аффилированности Компании и ПАО «Каршеринг
    Руссия» размещена на сайте Компании; размещение ценных бумаг эмитента
    происходит на организованных торгах.

    <br /><br /><br /><br />
    <div class="section-header">
      Информирование о рисках учета прав на ценные бумаги иностранных эмитентов
      в иностранном депозитарии
    </div>
    <br />ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» информирует депонентов о рисках учета
    прав на ценные бумаги иностранных эмитентов в иностранном депозитарии, в том
    числе в дружественных юрисдикциях. К таким рискам, помимо общих системных и
    правовых рисков, в частности относятся возможные последствия
    неподконтрольности иностранных депозитариев российским государственным
    органам и Банку России, повышенная вероятность сбоев при коммуникациях с
    иностранными депозитариями, подверженность санкционным и иным подобным
    ограничениям, потенциальная затрудненность участия в корпоративных действиях
    и получения доходов по ценным бумагам.
  </div>

  <div class="about-advantage">
    <h2>
      Уведомление о праве подать заявление об исключении из реестра лиц,
      признанных квалифицированными инвесторами
    </h2>
    <br /><br />Уважаемые Клиенты, уведомляем вас о том, что Вы вправе подать
    ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» заявление об исключении Вас из реестра лиц,
    признанных квалифицированными инвесторами. В случае подачи такого заявления
    Вы утратите возможность, пользуясь услугами ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ»,
    приобретать ценные бумаги и заключать договоры, являющиеся производными
    финансовыми инструментами, в отношении которых Вы были признаны ООО Концерн
    «ДЖЕНЕРАЛ-ИНВЕСТ» квалифицированным инвестором. <br /><br />Заявление об
    исключении из реестра лиц, признанных ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ»
    квалифицированными инвесторами, может быть направлено на бумажном носителе
    (лично или по почте) или в иной форме и иным способом с использованием
    средств связи, предусмотренных для обмена сообщениями соответствующим
    договором, заключенным с клиентом (за исключением обмена сообщениями
    посредством ИТС QUIK). <br /><br />Форма направления заявления об исключении
    из реестра лиц, признанных квалифицированными инвесторами, приведена в
    приложении к Регламенту признания лиц квалифицированными инвесторами ООО
    Концерн «ДЖЕНЕРАЛ-ИНВЕСТ». <br /><br />Регламент признания лиц
    квалифицированными инвесторами ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» размещен в
    разделах: <br /><a href="https://generalinvest.ru/information_disclosure"
      >https://generalinvest.ru/information_disclosure</a
    >

    <br /><br /><br /><a href="clients-information-archive"
      >Информация для клиентов (архив)</a
    >
    <br /><br /><br /><sup>1</sup> дата размещения информации 01.07.2023
  </div>

  <app-form></app-form>

  <app-footer></app-footer>
</div>
