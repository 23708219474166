<div id="column-slide" class="visible">
  <div id="logo-block-bg" class="logo-block-bg-full"></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="services-header">
    скусство управления<br />частным капиталом
    <div id="page-header-comment">Доверительное управление</div>
  </div>
  <div id="trust-management-slide"></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Услуги &nbsp;/&nbsp;Доверительное управление
  </div>

  <div id="logo-block-bg-b" class="hidden"></div>
</div>

<div id="column" class="visible">
  <div class="quote-block">
    <div id="quote-column">
      <div
        id="quote-photo"
        style="
          background: url('../../../assets/team/gubin-text.jpg') center center
            no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        class="wow up-anim"
        data-wow-offset="300"
      ></div>
      <div
        id="quote-text-block"
        class="wow anim-visible"
        data-wow-delay="0.4s"
        data-wow-offset="300"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div id="quote-text">
          От конкурентов нас отличает отсутствие «коробочных решений»,
          одинаковых для всех. В зависимости от задач каждого конкретного
          клиента мы меняем параметры инвестиционных продуктов, подбирая для
          него лучшие идеи, отвечающие именно его задачам. Каждое предложение мы
          формируем индивидуально, а наработанные связи с международными
          контрагентами позволяют нам делать это на максимально выгодных для
          инвестора условиях.
        </div>
        <div id="quote-name">
          <div>Алексей Губин</div>
          Директор по инвестициям
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50">
      <!--<a href="https://www.youtube.com/watch?v=nFKLtM5RFHQ" title="Инвестиционный процесс в General Invest" class="trust-bg popup-youtube"></a>-->
      <div class="trust-bg">
        <div class="trust-play-title">Видео об инвестиционном процессе</div>
        <a href="#youtube-dialog" class="trust-play-wrap popup-with-zoom-anim">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-play-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polygon points="10 8 16 12 10 16 10 8"></polygon>
          </svg>
        </a>
      </div>
      <div id="youtube-dialog" class="zoom-anim-dialog mfp-hide">
        <div style="max-width: 800px; margin: 0 auto">
          <div class="video-title">Инвестиционный процесс в General Invest</div>
          <div class="video-block">
            <iframe
              src="https://www.youtube.com/embed/nFKLtM5RFHQ"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              width="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="col50">
      <div class="row50 company-anot-t">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Преимущества работы с General Invest
            <div>
              <div class="company-anot-more advantage-click">
                Узнайте больше
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row50 company-anot-b">
        <div
          class="about-anot wow company-anim"
          data-wow-offset="100"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="about-anot-over">
            Специфика доверительного управления
            <div>
              <div class="company-anot-more specific-click">Узнайте больше</div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoblock-black advantage-infoblock">
        <div class="infoblock-header">Преимущества работы с General Invest</div>
        <div class="infoblock-item">Индивидуальный подход</div>
        <div class="infoblock-item">Конфиденциальность информации</div>
        <div class="infoblock-item">Высокий уровень сервиса</div>
        <div class="infoblock-transp"></div>
      </div>
      <div class="infoblock-black specific-infoblock">
        <div class="infoblock-header">Специфика доверительного управления</div>
        <div class="infoblock-item">
          Стратегию ведет профессиональный управляющий
        </div>
        <div class="infoblock-item">
          Уровень риска и доходности регламентирован
        </div>
        <div class="infoblock-item">
          Операции совершаются в рамках утвержденной декларации
        </div>
        <div class="infoblock-transp"></div>
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="200">Принцип работы</div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="200"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>1.</div>
        Постановка целей
      </div>
      <div class="advantage-info">
        Вы определяете ожидаемую доходность, валюту и срок инвестирования, мы
        определяем ваш допустимый уровень риска и предлагаем выбрать стратегию,
        подходящую вашему инвестиционному профилю.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="200"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>2.</div>
        Формирование портфеля
      </div>
      <div class="advantage-info">
        После выбора подходящей стратегии мы формируем индивидуальный портфель в
        соответствии с вашими приоритетами и задачами.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="200"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>3.</div>
        Работа профессионала
      </div>
      <div class="advantage-info">
        Все инвестиционные решения принимает управляющий, действующий в рамках
        целей, которые вы установили.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="200"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>4.</div>
        Контроль управления
      </div>
      <div class="advantage-info">
        Вы получаете регулярный отчет о доходности портфеля.
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50">
      <div class="infoblock-black-opacity-l">
        <div class="infoblock-header-c">Надёжность</div>
        <div class="infoblock-text-c">
          Денежные средства и ценные бумаги клиентов хранятся в Национальном
          расчетном депозитарии (НКО АО НРД) – центральном депозитарии России,
          который входит в Группу «Московская Биржа». Рейтинг надежности НРД:
          AA- (очень высокая степень надежности по системе Thomas Murray).
        </div>
      </div>
      <div class="trust-bg-l">
        <div class="company-header company-header-hide">
          <div class="double-border center-block">Надёжность</div>
        </div>
      </div>
    </div>
    <div class="col50">
      <div class="infoblock-black-opacity-r">
        <div class="infoblock-header-c">Экспертиза</div>
        <div class="infoblock-text-c">
          Портфельные управляющие General Invest обладают богатым опытом и
          ежегодно подтверждают свою экспертизу в международных рейтингах. В
          основе их решений — глубокое понимание рынка.
          <br /><br />Все их знания работают в ваших интересах. Портфельные
          управляющие отслеживают новостной фон в режиме online; проводят
          фундаментальный и технический анализ; понимают взаимосвязь событий на
          рынке и в экономике; видят тенденции и возможные сценарии развития
          трендов.
        </div>
      </div>
      <div class="trust-bg-r">
        <div class="company-header company-header-hide">
          <div class="double-border center-block">Экспертиза</div>
        </div>
      </div>
    </div>
  </div>

  <div class="strategy-block">
    <script>
      window.onload = function () {
        $(".multiple-item").slick({
          autoplay: false,
          autoplaySpeed: 6000,
          speed: 1200,
          prevArrow: '<div class="slick-prev"></div>',
          nextArrow: '<div class="slick-next"></div>',
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 2,
                speed: 1200,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                fade: true,
                slidesToShow: 1,
                speed: 600,
                slidesToScroll: 1,
              },
            },
          ],
        });
      };
    </script>

    <div class="about-advantage">
      <div class="strategy-header">Инвестиционная команда</div>
      <div id="team-group">
        <div id="team-block" style="opacity: 1; position: relative; top: 0">
          <div class="personal-block-marg speaker-margin">
            <div class="multiple-item slick-initialized slick-slider">
              <div class="slick-prev slick-arrow" style="display: block"></div>

              <div aria-live="polite" class="slick-list draggable">
                <div
                  class="slick-track"
                  style="opacity: 1; width: 3784px; left: -1032px"
                  role="listbox"
                >
                  <div
                    class="team-item slick-slide slick-cloned"
                    data-slick-index="-3"
                    aria-hidden="true"
                    tabindex="-1"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=55" tabindex="-1"
                          ><img src="/images/team/d-rezepov.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Димитрий Резепов</div>
                      <div class="team-position">
                        Портфельный управляющий, заместитель директора по
                        инвестициям
                      </div>
                    </div>
                  </div>
                  <div
                    class="team-item slick-slide slick-cloned"
                    data-slick-index="-2"
                    aria-hidden="true"
                    tabindex="-1"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=52" tabindex="-1"
                          ><img src="/images/team/zorina.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Ксения Зорина</div>
                      <div class="team-position">
                        Руководитель управления структурных продуктов
                      </div>
                    </div>
                  </div>
                  <div
                    class="team-item slick-slide slick-cloned"
                    data-slick-index="-1"
                    aria-hidden="true"
                    tabindex="-1"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=46" tabindex="-1"
                          ><img src="/images/team/simonova.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Татьяна Симонова</div>
                      <div class="team-position">
                        Портфельный управляющий по российским акциям
                      </div>
                    </div>
                  </div>
                  <div
                    class="team-item slick-slide slick-current slick-active"
                    data-slick-index="0"
                    aria-hidden="false"
                    tabindex="-1"
                    role="option"
                    aria-describedby="slick-slide00"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=47" tabindex="0"
                          ><img src="../../../assets/team/gubin.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Алексей Губин</div>
                      <div class="team-position">Директор по инвестициям</div>
                    </div>
                  </div>
                  <div
                    class="team-item slick-slide slick-active"
                    data-slick-index="1"
                    aria-hidden="false"
                    tabindex="-1"
                    role="option"
                    aria-describedby="slick-slide01"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=51" tabindex="0"
                          ><img src="../../../assets/team/m-smirnov.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Михаил Смирнов</div>
                      <div class="team-position">
                        Портфельный управляющий по глобальным рынкам,
                        заместитель директора по инвестициям и управлению
                      </div>
                    </div>
                  </div>
                  <div
                    class="team-item slick-slide slick-active"
                    data-slick-index="2"
                    aria-hidden="false"
                    tabindex="-1"
                    role="option"
                    aria-describedby="slick-slide02"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=55" tabindex="0"
                          ><img src="../../../assets/team/d-rezepov.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Димитрий Резепов</div>
                      <div class="team-position">
                        Портфельный управляющий, заместитель директора по
                        инвестициям
                      </div>
                    </div>
                  </div>
                  <div
                    class="team-item slick-slide"
                    data-slick-index="3"
                    aria-hidden="true"
                    tabindex="-1"
                    role="option"
                    aria-describedby="slick-slide03"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=52" tabindex="-1"
                          ><img src="../../../assets/team/zorina.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Ксения Зорина</div>
                      <div class="team-position">
                        Руководитель управления структурных продуктов
                      </div>
                    </div>
                  </div>
                  <div
                    class="team-item slick-slide"
                    data-slick-index="4"
                    aria-hidden="true"
                    tabindex="-1"
                    role="option"
                    aria-describedby="slick-slide04"
                    style="width: 274px"
                  >
                    <div class="team-item-photo-block">
                      <div class="team-item-photo">
                        <a href="/team?manager=46" tabindex="-1"
                          ><img src="../../../assets/team/simonova.png" alt=""
                        /></a>
                      </div>
                    </div>
                    <div class="team-item-info">
                      <div class="team-name">Татьяна Симонова</div>
                      <div class="team-position">
                        Портфельный управляющий по российским акциям
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="slick-next slick-arrow" style="display: block"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>

  <div class="quote-block">
    <div
      class="col25 docrow wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block nomargin doc-link">
        Регламент Доверительного управления
      </div>
      <div class="advantage-info">
        Актуально с 11.05.2023 по настоящее время <br /><br />Дата размещения
        04.05.2023 <br /><br /><a
          href="/docs/reglament_dov_upravleniya_2023_05.pdf"
          class="button-link-doc"
          >Скачать</a
        >
      </div>
    </div>
  </div>

  <div class="about-advantage">
    <div class="advantage-block link-all-docs" style="margin-top: 0">
      <a href="/asset_management_information" class="doc-link"
        >Все документы по Доверительному управлению →</a
      >
    </div>
  </div>

  <app-form></app-form>

  <app-footer></app-footer>
</div>
