import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('mobMenu', { static: true }) mobMenu!: ElementRef;
  @ViewChild('mobMenuContent', { static: true }) mobMenuContent!: ElementRef;

  constructor(private renderer: Renderer2, private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeMobMenu();
      });
  }

  openMenu(e: any) {
    e.target.parentNode.classList.add('hover');
    e.target.parentNode.children[1].style.opacity = '1';
  }

  closeMenu(e: any) {
    e.target.classList.remove('hover');
    e.target.children[1].style.opacity = '1';
  }

  openMobMenu() {
    this.renderer.setStyle(
      this.mobMenu.nativeElement,
      'transform',
      'translate3d(0, 0, 0)'
    );
    this.renderer.setStyle(
      this.mobMenuContent.nativeElement,
      'visibility',
      'visible'
    );
  }

  openMobSubMenu(e: any) {
    if (e.target.parentNode.classList.contains('pushy-submenu-open')) {
      e.target.parentNode.classList.add('pushy-submenu-closed');
      e.target.parentNode.classList.remove('pushy-submenu-open');
    } else {
      e.target.parentNode.classList.remove('pushy-submenu-closed');
      e.target.parentNode.classList.add('pushy-submenu-open');
    }
  }

  closeMobMenu() {
    this.renderer.removeStyle(this.mobMenu.nativeElement, 'transform');
    this.renderer.removeStyle(this.mobMenuContent.nativeElement, 'visibility');
  }
}
