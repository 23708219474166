<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Контакты</h1></div>
  <div
    id="page-header-block"
    style="
      background: url(/images/none.jpg) no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;Контакты
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div id="contacts-map">
    <div class="dragscroll">
      <div class="map-img"></div>
    </div>
    <div
      class="cmap-info-block wow company-anim"
      data-wow-offset="200"
      style="animation-name: visible-scale; visibility: visible"
    >
      <div class="double-border-map">
        Офисы General Invest расположены в крупнейших городах России: Москве,
        Санкт-Петербурге, Екатеринбурге, Новосибирске, Казани, Краснодаре и
        Тюмени. Офис материнской компании General Invest (Luxembourg) S.A.
        расположен в Люксембурге.
      </div>
    </div>
  </div>

  <script>
    $(document).ready(function () {
      $(".popup-gmaps").magnificPopup({
        disableOn: 700,
        type: "iframe",
        mainClass: "mfp-fade",
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false,
      });
    });
  </script>

  <div class="office-block">
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="100"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">Москва<br />центральный офис</div>
      <div class="advantage-info">
        107031, Россия, Москва,<br />ул. Петровка, д.7<br /><br />Телефон, факс:
        +7 (495) 727-00-77<br /><br /><a
            href="https://maps.google.com/maps?q=Петровка+ул.,+7,+Москва,+107031"
            class="popup-gmaps"
            rel="nofollow"
            >Показать на карте</a
          >
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="100"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">Санкт-Петербург</div>
      <div class="advantage-info">
        197110, Россия, Санкт-Петербург, Морской проспект, 15, лит. А, 12Н<br /><br />Телефон,
        факс: +7 (812) 602-99-90<br /><br /><a
            href="https://maps.google.com/maps?q=Морской+проспект,+15,+Санкт-Петербург,+197110"
            class="popup-gmaps"
            rel="nofollow"
            >Показать на карте</a
          >
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="100"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">Екатеринбург</div>
      <div class="advantage-info">
        620014, Россия, Екатеринбург,<br />ул. Бориса Ельцина, 1а<br /><br />Телефон,
        факс: +7 (343) 226-00-26<br /><br /><a
            href="https://maps.google.com/maps?q=ул.+Бориса+Ельцина,+1а,+Екатеринбург,+Свердловская+обл.,+Россия,+620014"
            class="popup-gmaps"
            rel="nofollow"
            >Показать на карте</a
          >
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="100"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">Казань</div>
      <div class="advantage-info">
        420107, Россия, Казань,<br />ул. Островского, д.98, офис 303<br /><br />Телефон,
        факс: +7 (843) 208-68-66<br /><br /><a
            href="https://maps.google.com/maps?q=ул.+Островского,+98,+Казань,+Респ.+Татарстан.,+Россия,+420107"
            class="popup-gmaps"
            rel="nofollow"
            >Показать на карте</a
          >
      </div>
    </div>
    <div class="clear"></div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.5s"
      data-wow-offset="100"
      style="
        animation-delay: 1.5s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">Краснодар</div>
      <div class="advantage-info">
        350063, Россия, Краснодарский край, г. Краснодар, ул. Н. Кондратенко
        5/1<br /><br />Телефон, факс: +7 (861) 205-59-07<br /><br /><a
            href="https://maps.google.com/maps?q=ул.+Кондратенко,+5,+Краснодар,+Краснодарский+край,+Россия,+350063"
            class="popup-gmaps"
            rel="nofollow"
            >Показать на карте</a
          >
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.8s"
      data-wow-offset="100"
      style="
        animation-delay: 1.8s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">Тюмень</div>
      <div class="advantage-info">
        625002, Россия, Тюмень,<br />ул. Первомайская, д. 1а<br /><br />Телефон:
        +7 (3452) 53-88-55<br /><br /><a
            href="https://maps.google.com/maps?q=ул.+Водопроводная,+1/1,+Тюмень,+Тюменская+обл.,+,+Россия,+625002"
            class="popup-gmaps"
            rel="nofollow"
            >Показать на карте</a
          >
      </div>
    </div>
  </div>

  <app-form></app-form>

  <div class="dragscroll">
    <div class="img"></div>
  </div>

  <app-footer></app-footer>
</div>
