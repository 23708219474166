<div class="feedback-block text-center">
  <div class="feedback-header-inline">Получить больше информации</div>
  <div class="form-block-inline">
    <div class="feedback-comment">
      Заполните ваши данные, в ближайшее время наш менеджер свяжется с вами.
    </div>
    <form
      name="feedback"
      id="feedback"
      method="post"
      action="/feedback/"
      enctype="multipart/form-data"
      [formGroup]="myForm"
    >
      <div class="form-block-center">
        <input
          type="text"
          id="name"
          name="name"
          value=""
          size="50"
          placeholder="Ваше имя"
          formControlName="name"
        />
        <div class="form-error"></div>
        <input
          type="text"
          id="phone"
          name="phone"
          value=""
          size="50"
          placeholder="Телефон"
          formControlName="phone"
        />
        <div class="form-error"></div>
        <input
          type="text"
          id="email"
          name="email"
          value=""
          size="50"
          placeholder="E-mail"
          formControlName="email"
        />
        <div class="form-error"></div>
        <div class="check-block">
          <div class="check-name">
            Даю согласие на
            <a href="/consent" target="_blank">обработку персональных данных</a>
            <input type="checkbox" id="agree" name="agree" value="0" />
            <div class="form-error check-error"></div>
          </div>
        </div>

        <input
          type="submit"
          id="submit_form"
          name="submit_form"
          value="Отправить"
          size=""
          (click)="sendForm()"
        />
        <div class="form-error" *ngIf="myForm.touched && myForm.invalid && !formSended">Форма заполнена некоректно</div>
      </div>
    </form>
    <div id="form-result" *ngIf="formSended">Форма успешно отправленна, скоро с вами свяжеться наш менеджер</div>
  </div>
</div>
