import { Component } from '@angular/core';

@Component({
  selector: 'app-brokerage-services',
  templateUrl: './brokerage-services.component.html',
  styleUrls: ['./brokerage-services.component.scss']
})
export class BrokerageServicesComponent {

}
