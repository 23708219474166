import { Component } from '@angular/core';
import { FormComponent } from 'src/app/UI/form/form.component';
import { ModalService } from 'src/app/http-service/modal.service';

@Component({
  selector: 'app-affiliate-programs',
  templateUrl: './affiliate-programs.component.html',
  styleUrls: ['./affiliate-programs.component.scss']
})
export class AffiliateProgramsComponent {
  constructor(private modalService: ModalService) { }

  openModal() {
    this.modalService.openModal(FormComponent); 
  }
}
