import { Component } from '@angular/core';
import { FormComponent } from '../UI/form/form.component';
import { ModalService } from '../http-service/modal.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  isAlertShown = false;
  constructor(private modalService: ModalService) { }

  openModal() {
    this.modalService.openModal(FormComponent); 
  }

  showAlert() {
    this.isAlertShown = !this.isAlertShown;
  }
}
