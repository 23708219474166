import { Component } from '@angular/core';
import { FormComponent } from 'src/app/UI/form/form.component';
import { ModalService } from 'src/app/http-service/modal.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent {
  constructor(private modalService: ModalService) { }

  openModal() {
    this.modalService.openModal(FormComponent); 
  }
}
