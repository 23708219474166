<div id="logo-block">
  <script type="text/javascript">
    function hideScroll() {
      $("body").css("overflow", "hidden");
    }
    function showScroll() {
      $("body").css("overflow", "auto");
    }
  </script>
  <div class="mob_menu bmmenu" (click)="openMobMenu()"></div>
  <div id="phone-top" class="greycolor">
    <a href="https://t.me/InfoGeneralinvest" target="_blank"
      >Связаться с нами</a
    >
  </div>

  <a routerLink="/"
    ><img src="../../assets/logo-r-15.svg" id="logo-r" alt="" class="visible"
  /></a>

  <div class="search-block">
    <form method="post" action="/search" style="margin: 0" id="search_form">
      <div class="search-field-block">
        <input
          type="text"
          id="searchwords"
          name="searchwords"
          value=""
          class="search-field"
          placeholder="Введите поисковый запрос"
        />
      </div>
      <div class="search-button s-w">
        <input type="image" name="search" src="/images/button_search.png" />
      </div>
      <div class="search-close"></div>
    </form>
  </div>

  <div id="main-menu" class="blackcolor">
    <div id="nav">
      <!-- <div
        class="second"
        (mouseover)="openMenu($event)"
        (mouseleave)="closeMenu($event)"
      >
        <span>Услуги</span>
        <div class="pos" style="opacity: 0">
          <div>
            <a routerLink="/asset_management">Доверительное управление</a><br />

            <a routerLink="/brokerage_services">Брокерское обслуживание</a
            ><br />

            <a routerLink="/depositary">Депозитарное обслуживание</a><br />

            <a routerLink="/advisory">Инвестиционное консультирование</a><br />

            <a routerLink="/analytics">Аналитика</a><br />

            <a routerLink="/clients-information">Информация для клиентов</a
            ><br />

            <a routerLink="/deliclub">Деликлуб</a><br />
          </div>
        </div>
      </div> -->

      <div
        class="second"
        (mouseover)="openMenu($event)"
        (mouseleave)="closeMenu($event)"
      >
        <a routerLink="/about">О компании</a>
        <div class="pos" style="opacity: 0">
          <div>
            <a routerLink="/history">История компании</a><br />

            <a routerLink="/team">Команда</a><br />

            <a routerLink="/how_to_become_a_client">Как стать клиентом</a><br />

            <a routerLink="/affiliate_programs">Как стать партнером</a><br />

            <a routerLink="/licenses">Лицензии</a><br />

            <a routerLink="/career">Карьера</a><br />

            <a routerLink="/social_responsibility">Благотворительность</a><br />
          </div>
        </div>
      </div>

      <div
        class="second"
        (mouseover)="openMenu($event)"
        (mouseleave)="closeMenu($event)"
      >
        <a routerLink="/press">Пресс-центр</a>
        <div class="pos" style="opacity: 0">
          <div>
            <a routerLink="/news">Новости</a><br />

            <a routerLink="/events">Мероприятия</a><br />

            <a routerLink="/press_news">Публикации в СМИ</a><br />

            <a routerLink="/contacts_for_media">Контакты для СМИ</a><br />
          </div>
        </div>
      </div>

      <a routerLink="/contacts">Контакты</a>
    </div>
  </div>
  <div id="lk-menu" class="redcolor">
    <a href="https://workspace.webmeta.finance" target="_blank">Войти /</a>
    <a
      href="https://workspace.webmeta.finance"
      target="_blank"
      class="withoutIcon"
    >
      регистрация</a
    >
  </div>
</div>

<div class="pushy pushy-left" #mobMenu>
  <div class="pushy-content" #mobMenuContent>
    <div class="menu-close" (click)="closeMobMenu()"></div>
    <div class="clear"></div>
    <ul class="mob-menu-list">
      <!-- <li class="pushy-submenu pushy-submenu-closed">
        <button (click)="openMobSubMenu($event)">Услуги</button>
        <ul>
          <li class="pushy-link">
            <a routerLink="/asset_management">Доверительное управление</a>
          </li>

          <li class="pushy-link">
            <a routerLink="/brokerage_services">Брокерское обслуживание</a>
          </li>

          <li class="pushy-link">
            <a routerLink="/depositary">Депозитарное обслуживание</a>
          </li>

          <li class="pushy-link">
            <a routerLink="/advisory">Инвестиционное консультирование</a>
          </li>

          <li class="pushy-link"><a routerLink="/analytics">Аналитика</a></li>

          <li class="pushy-link">
            <a routerLink="/clients-information">Информация для клиентов</a>
          </li>

          <li class="pushy-link"><a routerLink="/deliclub">Деликлуб</a></li>
        </ul>
      </li> -->

      <li class="pushy-submenu pushy-submenu-closed">
        <button (click)="openMobSubMenu($event)">О компании</button>
        <ul>
          <li class="pushy-link"><a routerLink="/about">О компании</a></li>

          <li class="pushy-link">
            <a routerLink="/history">История компании</a>
          </li>

          <li class="pushy-link"><a routerLink="/team">Команда</a></li>

          <li class="pushy-link">
            <a routerLink="/how_to_become_a_client">Как стать клиентом</a>
          </li>

          <li class="pushy-link">
            <a routerLink="/affiliate_programs">Как стать партнером</a>
          </li>

          <li class="pushy-link"><a routerLink="/licenses">Лицензии</a></li>

          <li class="pushy-link"><a routerLink="/career">Карьера</a></li>

          <li class="pushy-link">
            <a routerLink="/social_responsibility">Благотворительность</a>
          </li>
        </ul>
      </li>

      <li class="pushy-submenu pushy-submenu-closed">
        <button (click)="openMobSubMenu($event)">Пресс-центр</button>
        <ul>
          <li class="pushy-link"><a routerLink="/press">Пресс-центр</a></li>

          <li class="pushy-link"><a routerLink="/news">Новости</a></li>

          <li class="pushy-link"><a routerLink="/events">Мероприятия</a></li>

          <li class="pushy-link">
            <a routerLink="/press_news">Публикации в СМИ</a>
          </li>

          <li class="pushy-link">
            <a routerLink="/contacts_for_media">Контакты для СМИ</a>
          </li>
        </ul>
      </li>

      <li class="pushy-link"><a routerLink="/contacts">Контакты</a></li>

      <div id="lk-mob" class="redcolor">
        <a
          href="https://workspace.webmeta.finance"
          target="_blank"
          class="withoutIcon"
          >Войти /</a
        >
        <a
          href="https://workspace.webmeta.finance"
          target="_blank"
          class="withoutIcon"
        >
          регистрация</a
        >
      </div>
    </ul>
    <div id="menu-callback-button">
      <div id="phone-mob">
        <a
          href="https://t.me/InfoGeneralinvest"
          target="_blank"
          class="popup-form"
          >Связаться с нами</a
        >
      </div>
    </div>
  </div>
</div>
