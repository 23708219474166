<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Публикации в СМИ</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/licenses/page-bg-press.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/press">Пресс-центр</a>

    &nbsp;/&nbsp;Публикации в СМИ
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="news-list">
    <div class="personal-block-marg noflex">
      <div class="overflow-block">
        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/876"
              style="
                background: url('../../../assets/press/tatcenter.png') left
                  bottom no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">06.06.2024</div>
            <a href="/rus/press_news/876"
              >Облигации, золото и IPO — ключевые тренды для инвесторов
              Татарстана</a
            >
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/875"
              style="
                background: url('../../../assets/press/logo-gl.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">04.06.2024</div>
            <a href="/rus/press_news/875"
              >General Invest: инвестиции в настоящее и будущее</a
            >
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/874"
              style="
                background: url('../../../assets/press/rbk.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">28.05.2024</div>
            <a href="/rus/press_news/874">Ставка на Китай</a>
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/873"
              style="
                background: url('../../../assets/press/rbk.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">23.05.2024</div>
            <a href="/rus/press_news/873">Экономика США</a>
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/872"
              style="
                background: url('../../../assets/press/rbk.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">22.05.2024</div>
            <a href="/rus/press_news/872"
              >Обмен заблокированными активами продолжается</a
            >
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/871"
              style="
                background: url('../../../assets/press/rbk.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">21.05.2024</div>
            <a href="/rus/press_news/871">Инвестидеи на российском рынке</a>
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/870"
              style="
                background: url('../../../assets/press/dk-ru.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">20.05.2024</div>
            <a href="/rus/press_news/870"
              >Светлана Гараева: «Когда инвестиции – в удовольствие»</a
            >
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/869"
              style="
                background: url('../../../assets/press/rbk.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">08.05.2024</div>
            <a href="/rus/press_news/869"
              >Последний день для участия в обмене активами: как завершается
              первый этап</a
            >
          </div>
        </div>

        <div class="col30">
          <div class="press-list-block">
            <a
              href="/press_news/868"
              style="
                background: url('../../../assets/press/rbk.png') left bottom
                  no-repeat;
              "
            ></a>
          </div>
          <div class="press-list-header">
            <div class="date-news-list">26.04.2024</div>
            <a href="/rus/press_news/868"
              >Глобальный рынок: отчеты покажут направление</a
            >
          </div>
        </div>
      </div>
      <div id="filter-pag">
        ﻿
        <div class="nav">
          <strong>1</strong>

          <a page="2" href="/press_news/page-2">2</a>

          <a page="3" href="/press_news/page-3">3</a>

          ...

          <a page="95" href="/press_news/page-95">95</a>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
