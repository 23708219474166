import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  openModal$ = new Subject<any>();

  constructor() {}

  openModal(component: any) {
    this.openModal$.next(component);
  }

}
