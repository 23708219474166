import { Component } from '@angular/core';

@Component({
  selector: 'app-license-modal',
  templateUrl: './license-modal.component.html',
  styleUrls: ['./license-modal.component.scss']
})
export class LicenseModalComponent {

}
