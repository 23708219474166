import { Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from 'src/app/http-service/modal.service';

@Component({
  selector: 'app-demo-modal',
  templateUrl: './demo-modal.component.html',
  styleUrls: ['./demo-modal.component.scss']
})
export class DemoModalComponent implements OnInit {
  @ViewChild('modalContainer', { read: ViewContainerRef }) modalContainer!: ViewContainerRef;
  isOpen: boolean = false;
  componentRef!: ComponentRef<any>;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.modalService.openModal$.subscribe(component => {
      this.loadComponent(component);
      this.isOpen = true;
    });
  }

  loadComponent(component: any) {
    this.modalContainer.clear();
    this.componentRef = this.modalContainer.createComponent(component);
  }

  closeModal() {
    this.isOpen = false;
    this.modalContainer.clear();
  }

  preventClosing(event: Event) {
    event.stopPropagation();
  }
}
