<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Карьера</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/career/page-bg-client.jpg') no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about">О компании</a>

    &nbsp;/&nbsp;Карьера
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="about-advantage">
    <div class="strategy-header red-h" data-wow-offset="200">Преимущества</div>
    <div class="overflow-block">
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.3s"
          data-wow-offset="200"
          style="
            animation-delay: 0.3s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage01">
            <div class="point-header">
              <div class="number">1.</div>
              Возможность карьерного роста внутри компании
            </div>
          </div>
        </div>
      </div>
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.6s"
          data-wow-offset="200"
          style="
            animation-delay: 0.6s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage02">
            <div class="point-header">
              <div class="number">2.</div>
              Социальный пакет для всех сотрудников
            </div>
          </div>
        </div>
      </div>
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="0.9s"
          data-wow-offset="200"
          style="
            animation-delay: 0.9s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage03">
            <div class="point-header">
              <div class="number">3.</div>
              Работа в компании, входящей в рейтинг работодателей
            </div>
          </div>
        </div>
      </div>
      <div class="col25 strategy-block-over">
        <div
          class="wow company-anim"
          data-wow-delay="1.2s"
          data-wow-offset="200"
          style="
            animation-delay: 1.2s;
            animation-name: visible-scale;
            visibility: visible;
          "
        >
          <div class="strategy-item brokerage04">
            <div class="point-header">
              <div class="number">4.</div>
              Команда признанных на рынке профессионалов
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="quote-block-g">
    <div id="quote-column">
      <div
        id="quote-photo"
        style="
          background: url('../../../assets/career/yanovskaya-text-02.jpg')
            center center no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        class="wow up-anim"
        data-wow-offset="300"
      ></div>
      <div
        id="quote-text-block"
        class="wow anim-visible"
        data-wow-delay="0.4s"
        data-wow-offset="300"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div id="quote-text">
          Если наши клиенты — это то, ради чего мы существуем, то наши
          сотрудники – тот ресурс, благодаря которому мы стали лидерами рынка.
          Совместное достижение целей – двигатель нашей команды. В команду
          General Invest мы год за годом привлекаем сильнейшие кадры. Только
          такая высокая планка в отборе сотрудников может обеспечить нам
          лидерство в будущем.
        </div>
        <div id="quote-name">
          <div>Татьяна Яновская</div>
          Руководитель отдела по работе с персоналом
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
  <div class="quote-block-g">
    <div id="quote-column">
      <div
        id="quote-photo"
        style="
          background: url('../../../assets/career/novikova-text.jpg') center
            center no-repeat;
          animation-name: slowmove-up;
          visibility: visible;
        "
        class="wow up-anim"
        data-wow-offset="300"
      ></div>
      <div
        id="quote-text-block"
        class="wow anim-visible"
        data-wow-delay="0.4s"
        data-wow-offset="300"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div id="quote-text">
          Мы собрали самые полезные практики по работе с персоналом.
          Придерживаемся двух принципов: автономность и следование общей цели.
          Свобода сотрудников, забота о них, которая «вшита» в корпоративную
          культуру, привлекают профессионалов, позволяют добиваться целей
          быстро, а эффективность при этом не снижается, а только растет.
          Адаптационная стратегия, совместный досуг, материальная и
          нематериальная мотивация создают внутри комфортную обстановку. И, как
          мы любим часто повторять, это небольшие улучшения с большой любовью!
        </div>
        <div id="quote-name">
          <div>Мария Новикова</div>
          Руководитель по подбору и адаптации персонала
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>

  <div class="mainnews-list">
    <div class="col30 press-pr-info">
      <div>Развитие</div>
      Пройдя свой 15-летний путь, наша компания неоднократно менялась и
      трансформировалась. Каждый раз новые условия влекли новые улучшения,
      мотивировали нас расти и развиваться.
    </div>
    <div class="col60 press-pr-block">
      <div>
        <div class="vacancy-info">
          Мы знаем, что сотрудники ценят в нашей компании
        </div>
        <div class="vacancy-item">
          «Наш самый ценный актив – это ЛЮДИ. Очень важно, что наши взгляды и
          цели совпадают»
        </div>
        <div class="vacancy-item">
          «В компании существуют уникальные ТРАДИЦИИ, ценности и принципы. Это
          способствует сильному сплочению коллектива»
        </div>
        <div class="vacancy-item">
          «Безусловно, на каждом уровне у нас есть возможность влиять на
          развитие БИЗНЕСА, реализовывать большие идеи»
        </div>
        <div class="vacancy-item">
          «Культура и руководство неразрывно связаны. ЕДИНСТВО и устойчивость»
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>

  <div class="about-advantage">
    <div class="awards-header" data-wow-offset="200">
      Культура и ценности в нашей компании
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="200"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>1.</div>
        Справедливость
      </div>
      <div class="advantage-info">
        Взаимодействие с коллегами и командами мы выстраиваем по принципу
        справедливости. Если мы не правы, то мы это признаем и исправим
        ситуацию.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="200"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>2.</div>
        Здравый смысл
      </div>
      <div class="advantage-info">
        В рабочем процессе мы придерживаемся законов физики, математики, логики
        и объективности.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="200"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>3.</div>
        Коллегиальность
      </div>
      <div class="advantage-info">
        Каждый, кто имеет свое мнение, должен его высказать и быть услышанным.
        Каждый, кто не согласен, может оказаться прав.
      </div>
    </div>
    <div
      class="col25 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="200"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block">
        <div>4.</div>
        Проактивность
      </div>
      <div class="advantage-info">
        Мы ценим ориентацию на результат и ответственность за принимаемые
        решения.
      </div>
    </div>
  </div>

  <div class="company-history">
    <div class="col50">
      <div class="company-left">
        <div
          class="company-header absolute-header wow company-anim"
          data-wow-offset="200"
          style="animation-name: visible-scale; visibility: visible"
        >
          <span class="double-border"
            >General Invest входит в Топ-50 работодателей <br /><span
              class="company-anot-more"
              >По версии рейтинга работодателей HeadHunter за 2021 год в
              категории небольшие компании</span
            ></span
          >
        </div>
      </div>
    </div>
    <div class="col50">
      <div class="row50 company-anot-t">
        <div
          class="company-anot wow company-anim"
          data-wow-offset="200"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="company-anot-over">
            21 место в рейтинге работодателей России
            <div>
              <a
                href="https://rating.hh.ru/history/rating2021/summary/?tab=small"
                rel="nofollow"
                target="_blank"
                class="company-anot-more"
                >Среди 243 финалистов рейтинга</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row50 company-anot-b">
        <div
          class="company-anot wow company-anim"
          data-wow-offset="200"
          style="animation-name: visible-scale; visibility: visible"
        >
          <div class="company-anot-over">
            14 место в рейтинге лояльности сотрудников
            <div>
              <a
                href="https://rating.hh.ru/history/rating2021/subrating/?tab=2"
                rel="nofollow"
                target="_blank"
                class="company-anot-more"
                >Среди 783 финалистов рейтинга</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-advantage" style="padding-bottom: 0">
    <div class="awards-header" data-wow-offset="200">
      Карьера в General Invest
    </div>
    <div class="career-block">
      <div>
        Команда General Invest приглашает вас стать частью масштабных изменений.
        <br />В данном разделе мы регулярно анонсируем самые актуальные и свежие
        вакансии в Москве и наших региональных подразделениях. <br />Будьте в
        числе первых и присылайте ваши отклики, мы с радостью с вами
        свяжемся!<br /><br /><br />
      </div>
      <div>
        <div
          class="col50 wow anim-visible advantage-hidden"
          data-wow-delay="0.2s"
          data-wow-offset="200"
          style="
            animation-delay: 0.2s;
            animation-name: visible;
            visibility: visible;
          "
        >
          <div class="vacancy-info">Мы предлагаем:</div>
          <div class="vacancy-item">
            Офисы в современных бизнес-центрах класса «А»
          </div>
          <div class="vacancy-item">
            Официальное оформление в соответствии с ТК РФ
          </div>
          <div class="vacancy-item">
            График работы: пн-пт, 8-часовой рабочий день
          </div>
          <div class="vacancy-item">
            Привлекательная и прозрачная система оплаты труда и вознаграждений
            для sales и support подразделений
          </div>
          <div class="vacancy-item">
            Медицинская страховка, страховка для выезжающих заграницу
          </div>
          <div class="vacancy-item">
            Крутые коллеги, у которых можно многому научиться
          </div>
        </div>
        <div
          class="col50 wow anim-visible advantage-hidden"
          data-wow-delay="0.4s"
          data-wow-offset="200"
          style="
            animation-delay: 0.4s;
            animation-name: visible;
            visibility: visible;
          "
        >
          <div class="vacancy-item">
            Личностное развитие и рост: корпоративная электронная библиотека с
            книгами, курсами, тестами по бизнесу и саморазвитию, возможность
            прохождения бесплатного обучения и тренингов, участия в регулярных
            встречах корпоративных клубов и скидки на курсы online школ
          </div>
          <div class="vacancy-item">Реальные задачи, работа на результат</div>
          <div class="vacancy-item">
            Создаем продукты, которыми хотим пользоваться сами
          </div>
          <div class="vacancy-item">
            В нашей компании всегда можно рассчитывать на взаимопомощь и
            поддержку
          </div>
          <div class="vacancy-item">
            Понятная структура, открытое руководство
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <br /><br />
      <div>
        <div class="vacancy-info">И самое главное:</div>
        <div class="vacancy-item">
          Совместное достижение целей – двигатель нашей команды!
        </div>
      </div>
    </div>

    <div class="career-block">
      <svg
        width="200"
        height="55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.332 18.333V55h18.333c10.125 0 18.332-8.207 18.332-18.332S46.79 18.335 36.665 18.335l-18.333-.002Z"
          fill="url(#a)"
        ></path>
        <path
          d="M18.333 0C8.208 0 0 8.208 0 18.333c0 10.125 8.208 18.332 18.333 18.332h18.332V18.333C36.667 8.208 28.46 0 18.333 0Z"
          fill="url(#b)"
        ></path>
        <path
          d="M36.665 18.333H18.332v18.333h18.333V18.333Z"
          fill="url(#c)"
        ></path>
        <path
          d="M84.025 12.122h4.412v24.545h-4.412V12.122ZM91.44 18.293h4.048v2.387h.067c1.558-2.553 4.478-2.984 5.904-2.984 5.008 0 9.22 3.849 9.22 9.784 0 5.042-3.383 9.784-9.22 9.784-1.493 0-3.816-.398-5.54-2.587h-.066v8.126H91.44v-24.51Zm4.148 9.187c0 3.517 2.254 5.606 5.241 5.606 3.15 0 5.44-2.388 5.44-5.64 0-2.453-1.559-5.572-5.275-5.572-3.218 0-5.406 2.39-5.406 5.606ZM112.852 12.122h4.412v4.047h-4.412v-4.047Zm0 6.17h4.412v18.375h-4.412V18.291ZM120.269 18.293h4.048v1.691h.066c.597-.762 2.056-2.288 5.042-2.288 4.246 0 7.164 3.05 7.164 8.226v10.747h-4.412v-9.75c0-4.112-1.956-5.043-3.68-5.043-3.582 0-3.814 3.418-3.814 5.042v9.75h-4.412V18.294h-.002ZM161.965 12.125h2.719c4.512 0 5.266 3.05 5.266 4.962 0 1.047-.265 4.857-5.227 4.857h-2.76v-9.819h.002Zm2.705 8.054c.995 0 3.463-.12 3.463-3.092 0-3.105-2.561-3.197-3.556-3.197h-.796v6.289h.889ZM170.95 12.125h1.765v1.619h-1.765v-1.619Zm0 2.467h1.765v7.35h-1.765v-7.35ZM182.451 12.125h1.765v1.619h-1.765v-1.619Zm0 2.467h1.765v7.35h-1.765v-7.35ZM185.792 16.159h-.862v-1.567h.862v-2.467h1.765v2.467h1.061v1.567h-1.061v5.785h-1.765v-5.785ZM197.647 12.125h1.766v9.817h-1.766v-9.817ZM71.467 21.654a5.532 5.532 0 0 1 5.526 5.526v5.525h-5.526a5.532 5.532 0 0 1-5.526-5.525 5.532 5.532 0 0 1 5.526-5.526Zm0-3.958a9.484 9.484 0 0 0 0 18.967h9.484V27.18c.001-5.238-4.245-9.484-9.484-9.484ZM192.624 15.976c1.219 0 2.21.991 2.21 2.21v2.21h-2.21c-1.219 0-2.21-.99-2.21-2.21 0-1.219.991-2.21 2.21-2.21Zm0-1.583a3.793 3.793 0 0 0 0 7.587h3.794v-3.794a3.794 3.794 0 0 0-3.794-3.793ZM179.643 15.976v2.21c0 1.22-.991 2.21-2.21 2.21-1.219 0-2.21-.99-2.21-2.21 0-1.219.991-2.21 2.21-2.21h2.21Zm1.581-1.583h-3.793a3.794 3.794 0 1 0 3.793 3.793v-3.793ZM148.057 21.654a5.532 5.532 0 0 1 5.525 5.526v5.525h-5.525a5.532 5.532 0 0 1-5.526-5.525 5.532 5.532 0 0 1 5.526-5.526Zm0-3.958c-5.237 0-9.484 4.246-9.484 9.484 0 5.237 4.247 9.483 9.484 9.483h9.483V27.18c.002-5.238-4.244-9.484-9.483-9.484Z"
          fill="#1A1A1A"
        ></path>
        <path
          d="M181.224 21.393h-1.766v4.119h1.766v-4.12Z"
          fill="#1A1A1A"
        ></path>
        <path
          d="M179.553 25.513v-1.765h-4.148v1.765h4.148Z"
          fill="#1A1A1A"
        ></path>
        <defs>
          <linearGradient
            id="a"
            x1="36.666"
            y1="54.914"
            x2="36.666"
            y2="19.477"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0064FF"></stop>
            <stop offset=".095" stop-color="#0085FF"></stop>
            <stop offset=".222" stop-color="#0AF"></stop>
            <stop offset=".355" stop-color="#00C9FF"></stop>
            <stop offset=".493" stop-color="#00E1FF"></stop>
            <stop offset=".64" stop-color="#00F2FF"></stop>
            <stop offset=".8" stop-color="#00FCFF"></stop>
            <stop offset="1" stop-color="#0FF"></stop>
          </linearGradient>
          <linearGradient
            id="b"
            x1="18.333"
            y1="36.746"
            x2="18.333"
            y2="1.08"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF914B"></stop>
            <stop offset=".205" stop-color="#FF8E4A"></stop>
            <stop offset=".37" stop-color="#FF8449"></stop>
            <stop offset=".521" stop-color="#FF7346"></stop>
            <stop offset=".663" stop-color="#FF5B42"></stop>
            <stop offset=".8" stop-color="#FF3C3C"></stop>
            <stop offset=".93" stop-color="#FF1736"></stop>
            <stop offset="1" stop-color="#FF0032"></stop>
          </linearGradient>
          <linearGradient
            id="c"
            x1="-11.164"
            y1="61.123"
            x2="34.857"
            y2="21.102"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#fff"></stop>
            <stop offset="1"></stop>
          </linearGradient>
        </defs>
      </svg>
      <br /><br /><br />Всем работникам General Invest доступно единое цифровое
      решение ALPINA DIGITAL: платформа для развития и обучения сотрудников и
      самая полная коллекция книг, аудиокниг, саммари, курсов, видео, тестов по
      бизнесу и саморазвитию на русском языке. <br /><br />А это отзывы наших
      коллег, активно пользующихся платформой: <br /><br />
      <div class="vacancy-item">
        <span style="font-style: italic"
          >«Большое количество разделов. Бизнес-книги чередуются с другими
          жанрами. Отличное приложение!»</span
        >
      </div>
      <div class="vacancy-item">
        <span style="font-style: italic"
          >«Ритм мегаполиса научил нас жить в режиме многозадачности. По дороге
          на работу или на тренировке всегда под рукой аудиокнига от Альпины! А
          во время кофе-брейка мы с коллегами делимся рекомендациями, обсуждаем
          любимые бест-селлеры =)»</span
        >
      </div>
      <div class="vacancy-item">
        <span style="font-style: italic"
          >«Мне нравится электронная библиотека Alpina, могу сказать только
          положительные отзывы об этом сервисе. Часто пользуюсь, когда есть
          свободная минутка, особенно по дороге с работы или на работу. Удобно
          что есть приложение , быстро работает. <br />Широкий выбор как
          современных произведений, так и классической литературы. Удобный
          интерфейс позволяет легко найти нужную книгу»</span
        >
      </div>
    </div>
  </div>
</div>

<div class="about-advantage">
  <div class="awards-header" data-wow-offset="200">Наши вакансии</div>
  <div class="career-block">
    <div class="vacancy-name">
      Директор по продаже инвестиционных продуктов (Вся Россия)
    </div>
    <div>
      В связи с расширением команды и запуском новых каналов продаж мы
      приглашаем соискателей на вакансию "Директор по продажам инвестиционных
      продуктов" в офисах General Invest в Москве и регионах.<br /><br /><br />
    </div>
    <div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.2s"
        data-wow-offset="200"
        style="
          animation-delay: 0.2s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Основные задачи и проекты:</div>
        <div class="vacancy-item">
          Активное привлечение частных и институциональных клиентов на
          обслуживание в General Invest
        </div>
        <div class="vacancy-item">
          Профессиональные комплексные консультации клиентов по нашим продуктам
          и услугам (брокерское обслуживание, ДУ, структурные продукты,
          еврооблигации, международные рынки, life-style)
        </div>
        <div class="vacancy-item">
          Составление для клиентов индивидуального инвестиционного портфеля и
          его последующее ведение
        </div>
        <div class="vacancy-item">
          Формирование индивидуальных предложений, а также участие в разработке
          новых продуктов General Invest под запрос клиента
        </div>
        <div class="vacancy-item">
          Выстраивание долгосрочных отношений с клиентами General Invest и
          формирование собственного клиентского портфеля
        </div>
        <div class="vacancy-item">
          Консультирование клиентов по вопросам инвестирования, управления
          активами на фондовом рынке, налогообложению и обслуживанию в
          международных юрисдикциях
        </div>
      </div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.4s"
        data-wow-offset="200"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Будет здорово, если у вас:</div>
        <div class="vacancy-item">Высшее образование</div>
        <div class="vacancy-item">
          Сертификат брокерской\дилерской деятельности и управления ценными
          бумагами
        </div>
        <div class="vacancy-item">
          Опыт работы от 3 лет с клиентами сегмента HNWI / UHNWI
        </div>
        <div class="vacancy-item">
          Наличие собственной лояльной клиентской базы ОБЯЗАТЕЛЬНО
        </div>
        <div class="vacancy-item">
          Отличные знания и навыки в области продаж, консультирования и
          обслуживания клиентов
        </div>
        <div class="vacancy-item">
          Знание и опыт продаж линейки финансовых продуктов (ДУ, структурные
          продукты, еврооблигации, западные рынки)
        </div>
        <div class="vacancy-item">
          Инициатива в разработке и экспертизе по продуктам
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="allnews">
      <div class="nav">
        <a href="#10" class="button-link" (click)="openModal()">Отправить резюме</a>
      </div>
    </div>
  </div>

  <div class="career-block">
    <div class="vacancy-name">
      Ведущий специалист отдела внутреннего контроля в городе Москве
    </div>
    <div>
      Внутренний контроль деятельности профессионального участника рынка ценных
      бумаг. Взаимодействие с Банком России. Ответы на предписания и запросы
      информации.<br /><br /><br />
    </div>
    <div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.2s"
        data-wow-offset="200"
        style="
          animation-delay: 0.2s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Основные задачи и проекты:</div>
        <div class="vacancy-item">
          Формирование и проверка отчетности, направляемой в Банк России в
          соответствии с Указанием 6282-У (отчеты по формам 0420401, 0420404,
          0420409, 0420415, 0420416, 0420417, 0420431, нерегулярная отчетность)
        </div>
        <div class="vacancy-item">
          Постановка задач для автоматизации процесса сборки и проверки данных
        </div>
      </div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.4s"
        data-wow-offset="200"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Будет здорово, если у вас:</div>
        <div class="vacancy-item">Высшее образование (финансы, экономика)</div>
        <div class="vacancy-item">
          Опыт работы в профучастнике рынка ценных бумаг на должности, связанной
          с подготовкой и проверкой отчетности, направляемой в Банк России,
          обязателен
        </div>
        <div class="vacancy-item">
          Опыт составления отчетов по формам 0420401, 0420404, 0420409, 0420413,
          0420415, 0420416, 0420431
        </div>
        <div class="vacancy-item">
          Знание законодательства рынка ценных бумаг
        </div>
        <div class="vacancy-item">
          ПО: Web-кабинет НРД КД и ДКУ, ПО Луч, Fansy Depo (является
          преимуществом)
        </div>
        <div class="vacancy-item">
          Наличие свидетельства о квалификации участника финансового рынка
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="allnews">
      <div class="nav">
        <a href="#10" class="button-link" (click)="openModal()">Отправить резюме</a>
      </div>
    </div>
  </div>

  <div class="career-block">
    <div class="vacancy-name">
      Финансовый аналитик в офисе General&nbsp;Invest в г.&nbsp;Москве
    </div>
    <div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.2s"
        data-wow-offset="200"
        style="
          animation-delay: 0.2s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Основные задачи и проекты:</div>
        <div class="vacancy-item">
          Подготовка установленных форм управленческой отчетности организации в
          разрезе каналов продаж/продуктов, план/факт
        </div>
        <div class="vacancy-item">
          Создание новых форм отчетности по запросу
        </div>
        <div class="vacancy-item">
          Участие в процессе планирования и бюджетирования
        </div>
        <div class="vacancy-item">Расчет KPI бизнес-подразделений</div>
        <div class="vacancy-item">
          Расчет бонусных пулов бизнес-подразделений в соответствии с
          утвержденными системами премирования
        </div>
        <div class="vacancy-item">
          Плотное взаимодействие как с бизнес-подразделениями, так и с
          поддерживающими подразделениями в процессе работы
        </div>
      </div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.4s"
        data-wow-offset="200"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Будет здорово, если у вас:</div>
        <div class="vacancy-item">
          Опыт работы не менее 3 лет, предпочтительны компании финансового
          сектора (банки, управляющие компании, брокеры)
        </div>
        <div class="vacancy-item">
          Высшее экономическое либо техническое образование
        </div>
        <div class="vacancy-item">
          Ответственность, внимательность, финансовая грамотность, аналитический
          склад ума и системность мышления, способность к обучению, планированию
          и самоорганизации
        </div>
        <div class="vacancy-item">
          Продвинутый пользователь MS Excel, MS Power Point. Знание 1С. Плюсом
          будет знание Python, SQL, Power BI
        </div>
        <div class="vacancy-item">
          Английский язык не ниже Intermediate, знание финансовой терминологии
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="allnews">
      <div class="nav">
        <a href="#10" class="button-link" (click)="openModal()">Отправить резюме</a>
      </div>
    </div>
  </div>

  <div class="career-block">
    <div class="vacancy-name">
      Персональный брокер в офисе General&nbsp;Invest в г.&nbsp;Москве
    </div>
    <div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.2s"
        data-wow-offset="200"
        style="
          animation-delay: 0.2s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Основные задачи и проекты:</div>
        <div class="vacancy-item">
          Профессиональное сопровождение портфелей крупных клиентов компании
          General Invest, исходя из потребностей и целей инвестора
        </div>
        <div class="vacancy-item">
          Анализ текущей рыночной ситуации, корпоративных событий и
          макроэкономики
        </div>
        <div class="vacancy-item">
          Консультирование клиентов по различным аспектам работы на фондовом
          рынке
        </div>
        <div class="vacancy-item">Риск-профилирование инвесторов</div>
        <div class="vacancy-item">
          Формирование портфелей согласно целям клиента и его риск-профиля
        </div>
        <div class="vacancy-item">Экспертиза имеющихся портфелей клиентов</div>
        <div class="vacancy-item">
          Регулярный мониторинг портфелей инвесторов
        </div>
        <div class="vacancy-item">
          Предоставление рекомендаций по балансировке портфелей клиентов
        </div>
      </div>
      <div
        class="col50 wow anim-visible advantage-hidden"
        data-wow-delay="0.4s"
        data-wow-offset="200"
        style="
          animation-delay: 0.4s;
          animation-name: visible;
          visibility: visible;
        "
      >
        <div class="vacancy-info">Будет здорово, если у вас:</div>
        <div class="vacancy-item">
          Опыт работы в аналогичной должности 3+ лет
        </div>
        <div class="vacancy-item">
          Отличное знание инструментов фондового рынка (акции, облигации, FORTS,
          OTC)
        </div>
        <div class="vacancy-item">
          Знание макроэкономики и фундаментального анализа
        </div>
        <div class="vacancy-item">
          Наличие Свидетельств о квалификации - «Специалист по
          брокерско-дилерской деятельности и управлению ценными бумагами»,
          «Финансовый консультант» (желательно)
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="allnews">
      <div class="nav">
        <a href="#10" class="button-link" (click)="openModal()">Отправить резюме</a>
      </div>
    </div>
  </div>
</div>

<div class="about-advantage">
  <div class="awards-header" data-wow-offset="200">Охрана труда</div>
  <div
    class="col50 docrow wow anim-visible advantage-hidden"
    data-wow-delay="0.2s"
    data-wow-offset="200"
    style="animation-delay: 0.2s; animation-name: visible; visibility: visible"
  >
    <div class="advantage-block doc-link">
      Перечень рекомендуемых мероприятий по улучшению условий труда
    </div>
    <div class="advantage-info">
      <a
        href="/docs/uluschenie_usloviy_truda_0124.pdf"
        target="_blank"
        class="button-link"
        >Скачать</a
      >
    </div>
  </div>
  <div
    class="col50 docrow wow anim-visible advantage-hidden"
    data-wow-delay="0.4s"
    data-wow-offset="200"
    style="animation-delay: 0.4s; animation-name: visible; visibility: visible"
  >
    <div class="advantage-block doc-link">
      Сводная ведомость результатов проведения специальной оценки условий труда
    </div>
    <div class="advantage-info">
      <a
        href="/docs/ocenka_usloviy_truda_0124.pdf"
        target="_blank"
        class="button-link"
        >Скачать</a
      >
    </div>
  </div>
  <div
    class="col50 docrow wow anim-visible advantage-hidden"
    data-wow-delay="0.4s"
    data-wow-offset="200"
    style="animation-delay: 0.4s; animation-name: visible; visibility: visible"
  >
    <div class="advantage-block doc-link">
      QR-код безопасности (профилактика COVID-19)<span
        >В соответствии с постановлением Правительства Санкт-Петербурга от
        13.03.2020 № 121 ООО Концерн «ДЖЕНЕРАЛ-ИНВЕСТ» старается обеспечить
        соблюдение рекомендаций Роспотребнадзора по профилактике распространения
        новой коронавирусной. Для этого был принят «Стандарт безопасной
        деятельности» и получен QR-код, подтверждающий соответствие деятельности
        нашей организации требованиям Стандарта по адресу места осуществления
        деятельности в Санкт-Петербурге</span
      >
    </div>
    <div class="advantage-info">
      <a href="/docs/QR-covid-spb.pdf" target="_blank" class="button-link"
        >Скачать</a
      >
    </div>
  </div>
</div>

<div class="quote-block-g">
  <div id="quote-column">
    <div
      id="quote-photo"
      style="
        background: url('../../../assets/career/orlova-text.jpg') center center
          no-repeat;
        animation-name: slowmove-up;
        visibility: visible;
      "
      class="wow up-anim"
      data-wow-offset="300"
    ></div>
    <div
      id="quote-text-block"
      class="wow anim-visible"
      data-wow-delay="0.4s"
      data-wow-offset="300"
      style="
        animation-delay: 0.4s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div id="quote-text">
        Инвестиции в охрану труда необходимы и оправданы. Сегодня, в период
        глобальных изменений в экономике, охрана труда стала одной из важных
        составляющих в системе управления любой организацией. Для нас важно
        общественное, моральное и физическое благополучие каждого сотрудника.
        Цель охраны труда в нашей компании – это построение культуры
        безопасности, развитие нулевого травматизма и непрерывное внедрение
        новых технологий.
      </div>
      <div id="quote-name">
        <div>Галина Орлова</div>
        Специалист по охране труда
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>

<div id="10">
  <app-form></app-form>
</div>

<app-footer></app-footer>
