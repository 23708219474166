import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {
  @ViewChild('slickTrack', { static: true }) slickTrack!: ElementRef;

  public indexYear: number = 0;

  constructor(private renderer: Renderer2) {}

  slideRight() {
    this.indexYear++;
    if(this.indexYear > 15){
      this.indexYear = 0
    }
    this.slide();
  }
  slideLeft() {
    this.indexYear--;
    if(this.indexYear < 0){
      this.indexYear = 15;
    }
    this.slide();
  }

  slide() {
    this.renderer.setStyle(
      this.slickTrack.nativeElement,
      'left',
      `-${this.indexYear * 370}px`
    );
  }

  yearSlide(index: number) {
    this.indexYear = index;
    this.slide();
  }
}
