import { Component } from '@angular/core';
import { FormComponent } from 'src/app/UI/form/form.component';
import { ModalService } from 'src/app/http-service/modal.service';

@Component({
  selector: 'app-how-to-become-a-client',
  templateUrl: './how-to-become-a-client.component.html',
  styleUrls: ['./how-to-become-a-client.component.scss']
})
export class HowToBecomeAClientComponent {
  constructor(private modalService: ModalService) { }

  openModal() {
    this.modalService.openModal(FormComponent); 
  }
}
