<div id="column-slide-second" class="visible">
  <div id="logo-block-bg" class=""></div>
  <app-header></app-header>
  <div class="clear"></div>

  <div id="page-header"><h1>Благотворительность</h1></div>
  <div
    id="page-header-block"
    style="
      background: url('../../../assets/social_responsibility/page-bg-press\ \(1\).jpg')
        no-repeat;
      background-size: cover;
      background-position-x: 40%;
      background-position-y: 50%;
    "
  ></div>

  <div id="pathway">
    <a href="/">Главная страница</a>

    &nbsp;/&nbsp;<a href="/about">О компании</a>

    &nbsp;/&nbsp;Благотворительность
  </div>

  <div id="logo-block-bg-b" class=""></div>
</div>

<div id="column" class="visible">
  <div class="about-points" style="padding-bottom: 0">
    <div class="col30 press-pr-info social">
      В этом году мы решили поддержать деятельность благотворительного Фонда
      Тереза и, в качестве новогоднего подарка, сделали щедрое пожертвование в
      пользу тех, кто оказался в сложной ситуации и нуждается в помощи.
      <br /><br />Мы верим в то, что даже маленькое доброе дело может спасти
      целый мир и уверены, что вы поддержите наш порыв. Давайте вместе положим
      начало добрым традициям!
    </div>

    <div class="col60 press-pr-block social">
      <span
        class="hclient-header wow up-anim"
        style="animation-name: slowmove-up; visibility: visible"
        >Благотворительный фонд</span
      >
      <br /><br /><br />Благотворительный Фонд «Тереза» оказывает всестороннюю
      поддержку многодетным семьям, пожилым людям и всем тем, кто оказался в
      трудной жизненной ситуации, как с материальной точки зрения, так и
      функциональной, предлагая обучение и поддержку тем, кто в этом нуждается.
      <br /><br />Фонд также помогает детям-сиротам адаптироваться во взрослой
      жизни, оказывая им помощь в трудоустройстве. Для этого действует
      специальная программа «Адаптация воспитанников детских домов», благодаря
      которой только за последние три месяца 10 детей смогли найти себе работу
      по душе. <br /><br />Мы в Фонде «Тереза» уверены, что любое развитие
      начинается с семьи, поэтому всегда откликаемся на просьбы о помощи. Чтобы
      у нас было больше возможностей для осуществления нашей миссии, нам нужна и
      ваша поддержка тоже.
      <div class="social-logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#1D73B3"
          viewBox="0 0 440.46 93.21"
          width="150"
        >
          <g>
            <path
              d="M109.6,71.38A23.17,23.17,0,0,0,91.74,83.8a21.46,21.46,0,0,0-2.47,10.69,22.11,22.11,0,0,0,4.76,14c1.18,1.58,32.17,32.27,33,32.73A3.74,3.74,0,0,0,132,139.7a4.54,4.54,0,0,0,.2-3.22c-.09-.27-3.7-4-8-8.25s-7.9-7.92-8-8.14a1.32,1.32,0,0,1,1.31-1.87c.47,0,1.58,1,8.57,7.9,8.44,8.34,8.39,8.3,10,8.29a4.42,4.42,0,0,0,2.9-1.68,4.31,4.31,0,0,0,.4-3.33c-.18-.56-1.88-2.34-8.21-8.63-8.64-8.59-8.5-8.41-7.54-9.37a1.38,1.38,0,0,1,1-.46c.54,0,1.36.75,8.6,7.9,8.72,8.59,8.55,8.45,10.36,8.22a3.61,3.61,0,0,0,2.78-5.19c-.27-.61-2.31-2.74-8.19-8.56-8.46-8.41-8.31-8.23-7.36-9.19s.77-1.11,9.45,7.45,8.5,8.4,10.38,8.25a3.35,3.35,0,0,0,2.85-2,3.39,3.39,0,0,0,.11-3.15c-.24-.49-3.11-3.44-8.68-9-8.3-8.16-8.35-8.21-9.42-8.51a17.69,17.69,0,0,1-5.92-3.28L129,93.4l-4,3.94a65.2,65.2,0,0,1-4.88,4.54,9.33,9.33,0,0,1-9.3.43c-3.23-1.6-4.7-4.4-3.53-6.76.14-.29,4.48-4.82,9.63-10l9.38-9.5-.89-.65a28.07,28.07,0,0,0-6.17-3,25.26,25.26,0,0,0-9.67-1Zm0,0"
              transform="translate(-89.27 -69.86)"
              class="cls-1"
            ></path>
            <path
              d="M142.11,71.21a23.45,23.45,0,0,0-10.77,4.18c-1.76,1.24-21.61,21.29-21.6,21.84,0,2,3.51,3.71,6.46,3.22,1.93-.32,2.56-.79,7.48-5.72,5.47-5.5,5-5.28,7.24-3.35a17.23,17.23,0,0,0,5,3.08,7.47,7.47,0,0,0,3,.39,5,5,0,0,0,3.09-.6,11.49,11.49,0,0,0,2-1.29c1.05-.93,1.54-1.19,2-1a1.28,1.28,0,0,1,1,1.35c0,.6-.12.78-1,1.62a13.63,13.63,0,0,1-4.47,2.49c-.35,0-.64.16-.64.22s3.35,3.44,7.43,7.51c7.37,7.32,7.57,7.54,8.25,9.37l.2.54,3-3.1c4-4.14,5.77-6.8,7-10.62A25.9,25.9,0,0,0,167.53,90,23.3,23.3,0,0,0,149,71.44a29,29,0,0,0-6.92-.23Zm0,0"
              transform="translate(-89.27 -69.86)"
              class="cls-1"
            ></path>
          </g>
          <g>
            <g>
              <rect
                x="159.37"
                y="1.23"
                width="3.23"
                height="69.67"
                class="cls-1"
              ></rect>
              <rect
                x="152.27"
                y="1.23"
                width="2.09"
                height="69.67"
                class="cls-1"
              ></rect>
              <polygon
                points="197.43 18.43 197.43 1.23 166.28 1.23 166.28 70.9 197.43 70.9 197.43 53.57 170.55 53.57 170.55 43.59 192.67 43.59 192.67 28.16 170.55 28.16 170.55 18.43 197.43 18.43"
                class="cls-1"
              ></polygon>
            </g>
            <g>
              <rect
                x="264.54"
                y="1.23"
                width="2.09"
                height="69.67"
                class="cls-1"
              ></rect>
              <rect
                x="271.65"
                y="1.23"
                width="3.23"
                height="69.67"
                class="cls-1"
              ></rect>
              <polygon
                points="309.7 18.43 309.7 1.23 278.56 1.23 278.56 70.9 309.7 70.9 309.7 53.57 282.82 53.57 282.82 43.59 304.95 43.59 304.95 28.16 282.82 28.16 282.82 18.43 309.7 18.43"
                class="cls-1"
              ></polygon>
            </g>
            <g>
              <g>
                <path
                  d="M238.88,88.18H222.2v52.38H203.65V88.18H186.93V70.89h51.95Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M328.25,140.56l-18.62-30.25v30.25h-18.5L291,70.89H317.5c15,0,25.8,10.16,25.8,22.65a21,21,0,0,1-12.06,19.06l18.67,28Zm-18.71-39.07,7.56.05c3.94,0,7.52-2.73,7.52-7s-3.62-7-7.52-7h-7.56Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M404.64,128.76l11.19-11c2.89,4.27,8.29,6.87,14.13,6.87,3.93,0,7.78-1.43,7.78-4.28,0-3.59-7.13-6.74-14-9.77C415,106.81,407.4,101,407.4,90.52c0-12.06,11.45-20.66,24.68-20.66,10.63,0,18.92,5,21.64,11.88L443.05,92c-2.07-3.68-7.43-5.27-10.75-5.27-3.81,0-6.53,1.94-6.53,4.32,0,3.2,4.93,4.23,12.44,7.39,10.89,4.58,18.11,11.23,18.11,21.52,0,13.27-12.36,21.52-26.32,21.52C418.55,141.51,408.13,136.46,404.64,128.76Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M506,132.43h-26l-3.54,8.13H456.18L488.55,70h8.77l32.41,70.53H509.46Zm-5.88-13.52L493,102.4l-7.18,16.51Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
              </g>
              <g>
                <path
                  d="M187.92,159.94V148.58h7v1.92h-5.09v2.6h2.67a3,3,0,0,1,2.37.94,3.54,3.54,0,0,1,.86,2.49,4,4,0,0,1-.73,2.38,2.85,2.85,0,0,1-2.5,1Zm1.87-4.92v3h2a4.18,4.18,0,0,0,1.12-.11,1.13,1.13,0,0,0,.6-.48,1.5,1.5,0,0,0,.26-.89c0-1-.63-1.52-1.9-1.52Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M201.91,159.94v-6.52H199.5v3.76a7.47,7.47,0,0,1-.12,1.48,2,2,0,0,1-.42.93,1.16,1.16,0,0,1-.59.41,3.34,3.34,0,0,1-.8.08,8.34,8.34,0,0,1-1.12-.14v-1.61a6.8,6.8,0,0,0,.74,0,.39.39,0,0,0,.41-.22,2.87,2.87,0,0,0,.11-1v-5.42h6v8.23Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M206.84,154.21l-1.62-.36a3.11,3.11,0,0,1,1-1.79,3.06,3.06,0,0,1,1.95-.55,3.81,3.81,0,0,1,1.73.32,1.88,1.88,0,0,1,.85.87,5.25,5.25,0,0,1,.26,2l0,2.54a12.26,12.26,0,0,0,.08,1.6,5.9,5.9,0,0,0,.32,1.11H209.6l-.24-.9a3.37,3.37,0,0,1-1,.82,2.39,2.39,0,0,1-1.11.27,2.12,2.12,0,0,1-1.61-.66,2.46,2.46,0,0,1-.63-1.77,2.77,2.77,0,0,1,.26-1.22,2.14,2.14,0,0,1,.72-.83,4.9,4.9,0,0,1,1.48-.55,8.68,8.68,0,0,0,1.73-.53,2.8,2.8,0,0,0-.09-.9.77.77,0,0,0-.36-.35,1.6,1.6,0,0,0-.73-.14,1.17,1.17,0,0,0-.76.22A1.52,1.52,0,0,0,206.84,154.21Zm2.38,1.78a8.54,8.54,0,0,1-1.06.32,2.61,2.61,0,0,0-1.08.48,1,1,0,0,0-.26.69,1.18,1.18,0,0,0,.28.8.93.93,0,0,0,.71.32,1.29,1.29,0,0,0,.75-.26,1.45,1.45,0,0,0,.52-.62,3.88,3.88,0,0,0,.14-1.3Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M212.68,159.94v-8.23h4.55v1.71h-2.76v6.52Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M217.75,155.71a5,5,0,0,1,.48-2.21,3.53,3.53,0,0,1,1.26-1.49,3.14,3.14,0,0,1,1.74-.5,3,3,0,0,1,2.56,1.28,4.94,4.94,0,0,1,.93,3,5.16,5.16,0,0,1-.49,2.29,3.6,3.6,0,0,1-1.27,1.55,3.09,3.09,0,0,1-1.72.51,3.14,3.14,0,0,1-2.5-1.15A4.84,4.84,0,0,1,217.75,155.71Zm1.83.11a3.08,3.08,0,0,0,.49,1.9,1.46,1.46,0,0,0,1.18.64,1.42,1.42,0,0,0,1.16-.65,3.15,3.15,0,0,0,.48-1.9,3.05,3.05,0,0,0-.49-1.88,1.39,1.39,0,0,0-2.34,0A3.14,3.14,0,0,0,219.58,155.82Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M225.32,153.42v-1.71h6.12v1.71h-2.16v6.52h-1.8v-6.52Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M236.91,159.94h-4.39v-8.23h4c1.5,0,2.25.71,2.25,2.14a1.78,1.78,0,0,1-1.13,1.87,2,2,0,0,1,1.37,2.05,2.14,2.14,0,0,1-.58,1.57A2.07,2.07,0,0,1,236.91,159.94Zm-2.6-6.85v2h1.31c1,0,1.44-.35,1.44-1s-.48-1-1.44-1Zm0,3.35v2.12h1.55c.89,0,1.34-.35,1.34-1.05a1,1,0,0,0-.34-.8,2.6,2.6,0,0,0-1.47-.27Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M240.08,155.71a5,5,0,0,1,.48-2.21,3.53,3.53,0,0,1,1.26-1.49,3.14,3.14,0,0,1,1.74-.5,3,3,0,0,1,2.56,1.28,4.94,4.94,0,0,1,.93,3,5.16,5.16,0,0,1-.49,2.29,3.6,3.6,0,0,1-1.27,1.55,3.09,3.09,0,0,1-1.72.51,3.16,3.16,0,0,1-2.5-1.15A4.84,4.84,0,0,1,240.08,155.71Zm1.83.11a3.08,3.08,0,0,0,.49,1.9,1.46,1.46,0,0,0,1.18.64,1.42,1.42,0,0,0,1.16-.65,3.15,3.15,0,0,0,.48-1.9,3.05,3.05,0,0,0-.49-1.88,1.39,1.39,0,0,0-2.34,0A3.14,3.14,0,0,0,241.91,155.82Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M248.4,151.71h1.66v1.21a2.59,2.59,0,0,1,.9-1,2.11,2.11,0,0,1,1.2-.38,2.45,2.45,0,0,1,2,1.11,5,5,0,0,1,.84,3.13,5.38,5.38,0,0,1-.84,3.25,2.42,2.42,0,0,1-2,1.13,1.94,1.94,0,0,1-1-.26,3.23,3.23,0,0,1-1-.94v4.14H248.4Zm1.76,4a3.61,3.61,0,0,0,.46,2.06,1.3,1.3,0,0,0,1.09.66,1.21,1.21,0,0,0,1-.61,3.59,3.59,0,0,0,.42-2,3.53,3.53,0,0,0-.43-1.94,1.26,1.26,0,0,0-1-.63,1.29,1.29,0,0,0-1.08.61A3.18,3.18,0,0,0,250.16,155.68Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M256.36,159.94v-8.23h1.71v5.16l2.74-5.16h1.77v8.23h-1.72v-5.28l-2.79,5.28Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M263.6,153.42v-1.71h6.12v1.71h-2.16v6.52h-1.79v-6.52Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M274.7,157.33l1.78.36a3.52,3.52,0,0,1-1.11,1.84,2.84,2.84,0,0,1-1.83.6,2.79,2.79,0,0,1-2.31-1,4.93,4.93,0,0,1-1-3.26,5.27,5.27,0,0,1,1-3.31,2.59,2.59,0,0,1,2.13-1.05,2.72,2.72,0,0,1,2.27,1.07,5.88,5.88,0,0,1,.94,3.62v.25h-4.47a2.5,2.5,0,0,0,.44,1.52,1.25,1.25,0,0,0,1,.54C274.13,158.51,274.51,158.11,274.7,157.33Zm.1-2.21a2.45,2.45,0,0,0-.41-1.45,1.15,1.15,0,0,0-.93-.49,1.17,1.17,0,0,0-.95.51,2.32,2.32,0,0,0-.39,1.43Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M282.7,159.94v-6.52h-2.41v3.76a7.47,7.47,0,0,1-.12,1.48,2.11,2.11,0,0,1-.42.93,1.16,1.16,0,0,1-.59.41,3.38,3.38,0,0,1-.81.08,8.45,8.45,0,0,1-1.11-.14v-1.61a6.8,6.8,0,0,0,.74,0,.39.39,0,0,0,.41-.22,3,3,0,0,0,.1-1v-5.42h6v8.23Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M286.32,159.94v-8.23h1.79v3.41h2c1.17,0,1.93.26,2.27.78a3,3,0,0,1,.51,1.64c0,1.6-.85,2.4-2.56,2.4Zm1.79-3.49v2.11h1.82a.93.93,0,0,0,1.07-1.05,1,1,0,0,0-.33-.8,2.13,2.13,0,0,0-1.24-.26Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M294.21,159.94v-8.23H296v3h2.56v-3h1.79v8.23h-1.79v-3.47H296v3.47Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M302.18,159.94v-8.23H304v3.41h2c1.17,0,1.93.26,2.27.78a3,3,0,0,1,.51,1.64c0,1.6-.85,2.4-2.56,2.4Zm1.79-3.49v2.11h1.82a.94.94,0,0,0,1.07-1.05,1,1,0,0,0-.33-.8,2.15,2.15,0,0,0-1.24-.26Zm6.18,3.49v-8.23h1.79v8.23Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M313.72,159.94v-8.23h1.72v5.16l2.74-5.16H320v8.23h-1.72v-5.28l-2.79,5.28Zm4.38-11.5H319a2.59,2.59,0,0,1-.62,1.67,1.77,1.77,0,0,1-1.36.59,1.8,1.8,0,0,1-1.36-.59,2.64,2.64,0,0,1-.62-1.67h.87c.07.75.44,1.12,1.11,1.12S318,149.19,318.1,148.44Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M330.89,148.58v1.12q4.07.2,4.07,4.46t-4.07,4.46v1.32H329v-1.32c-2.7-.13-4-1.61-4-4.46s1.35-4.33,4-4.46v-1.12ZM329,156.7v-5.08c-1.48,0-2.21.86-2.21,2.56S327.54,156.7,329,156.7Zm1.87-5.08v5.08c1.49,0,2.23-.86,2.23-2.57S332.38,151.62,330.89,151.62Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M336,155.71a5,5,0,0,1,.48-2.21,3.53,3.53,0,0,1,1.26-1.49,3.18,3.18,0,0,1,1.74-.5,3,3,0,0,1,2.56,1.28,4.94,4.94,0,0,1,.93,3,5.3,5.3,0,0,1-.48,2.29,3.75,3.75,0,0,1-1.27,1.55A3.24,3.24,0,0,1,337,159,4.79,4.79,0,0,1,336,155.71Zm1.83.11a3.15,3.15,0,0,0,.49,1.9,1.47,1.47,0,0,0,1.18.64,1.42,1.42,0,0,0,1.16-.65,3.15,3.15,0,0,0,.48-1.9,3.05,3.05,0,0,0-.49-1.88,1.38,1.38,0,0,0-2.33,0A3.07,3.07,0,0,0,337.85,155.82Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M344.32,159.94v-8.23h1.79v3h2.56v-3h1.79v8.23h-1.79v-3.47h-2.56v3.47Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M358.59,158.13h.71v4h-1.44v-2.18h-5.12v2.18H351.3v-4H352c.71-1,1.07-3.13,1.11-6.42h5.47Zm-4.83,0h3v-4.61h-2.15A10.25,10.25,0,0,1,353.76,158.13Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M364.08,159.94v-8.23h6v8.23h-1.8v-6.52h-2.41v6.52Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M371.58,155.71a5,5,0,0,1,.49-2.21,3.51,3.51,0,0,1,1.25-1.49,3.19,3.19,0,0,1,1.75-.5,3,3,0,0,1,2.55,1.28,4.94,4.94,0,0,1,.93,3,5.3,5.3,0,0,1-.48,2.29,3.68,3.68,0,0,1-1.27,1.55,3.24,3.24,0,0,1-4.22-.64A4.84,4.84,0,0,1,371.58,155.71Zm1.83.11a3.15,3.15,0,0,0,.49,1.9,1.47,1.47,0,0,0,1.18.64,1.41,1.41,0,0,0,1.16-.65,3.15,3.15,0,0,0,.48-1.9,3.05,3.05,0,0,0-.49-1.88,1.38,1.38,0,0,0-2.33,0A3.13,3.13,0,0,0,373.41,155.82Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M386.29,158.13H387v4h-1.44v-2.18h-5.12v2.18H379v-4h.71c.71-1,1.08-3.13,1.11-6.42h5.47Zm-4.83,0h3v-4.61h-2.15A10.25,10.25,0,0,1,381.46,158.13Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M394.56,158.13h.71v4h-1.44v-2.18h-5.12v2.18h-1.44v-4H388c.7-1,1.07-3.13,1.1-6.42h5.47Zm-4.83,0h3v-4.61h-2.15A10.25,10.25,0,0,1,389.73,158.13Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M400.42,157.33l1.77.36a3.4,3.4,0,0,1-1.11,1.84,2.84,2.84,0,0,1-1.83.6,2.79,2.79,0,0,1-2.31-1,5,5,0,0,1-1-3.26,5.21,5.21,0,0,1,1-3.31,2.56,2.56,0,0,1,2.12-1.05,2.71,2.71,0,0,1,2.27,1.07,5.88,5.88,0,0,1,1,3.62v.25h-4.47a2.43,2.43,0,0,0,.44,1.52,1.25,1.25,0,0,0,1,.54C399.84,158.51,400.23,158.11,400.42,157.33Zm.09-2.21a2.32,2.32,0,0,0-.41-1.45,1.13,1.13,0,0,0-.92-.49,1.17,1.17,0,0,0-1,.51,2.25,2.25,0,0,0-.38,1.43Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M403.68,151.71h1.66v1.21a2.75,2.75,0,0,1,.9-1,2.11,2.11,0,0,1,1.2-.38,2.46,2.46,0,0,1,2,1.11,5.12,5.12,0,0,1,.83,3.13,5.38,5.38,0,0,1-.84,3.25,2.41,2.41,0,0,1-2,1.13,1.94,1.94,0,0,1-1-.26,3.35,3.35,0,0,1-1-.94v4.14h-1.79Zm1.77,4a3.7,3.7,0,0,0,.45,2.06,1.31,1.31,0,0,0,1.09.66,1.21,1.21,0,0,0,1-.61,3.59,3.59,0,0,0,.42-2,3.44,3.44,0,0,0-.43-1.94,1.25,1.25,0,0,0-1.05-.63,1.31,1.31,0,0,0-1.08.61A3.19,3.19,0,0,0,405.45,155.68Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M416.25,159.94h-1.79v-3.52q-.56,0-1,1.14l-.84,2.38h-1.92l.95-2.28c.48-1.14,1-1.79,1.5-1.95a1.43,1.43,0,0,1-.59-.58,6.38,6.38,0,0,1-.37-1.07c-.13-.47-.27-.74-.42-.82a2,2,0,0,0-.83-.11v-1.41a4.4,4.4,0,0,1,.69-.07,1.42,1.42,0,0,1,1.19.46,4.69,4.69,0,0,1,.64,1.44,7.06,7.06,0,0,0,.44,1.24.6.6,0,0,0,.52.27v-3.35h1.79v3.35a.65.65,0,0,0,.48-.23,3.84,3.84,0,0,0,.5-1.34A2.84,2.84,0,0,1,418,152a1.59,1.59,0,0,1,1.06-.37,4.5,4.5,0,0,1,.71.07v1.41h-.19a1.42,1.42,0,0,0-.65.12c-.15.08-.29.35-.42.82a6.21,6.21,0,0,1-.37,1.06,1.43,1.43,0,0,1-.59.58c.51.16,1,.81,1.49,1.95l1,2.28h-1.92l-.86-2.38q-.42-1.17-1-1.14Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M420.83,159.94v-8.23h1.8v3.35a.91.91,0,0,0,.55-.24,3.64,3.64,0,0,0,.48-1.19,5.4,5.4,0,0,1,.64-1.48,1.38,1.38,0,0,1,1.22-.5,4.4,4.4,0,0,1,.69.07v1.41H426a1.42,1.42,0,0,0-.65.12c-.15.08-.29.35-.42.82a6.91,6.91,0,0,1-.37,1.06,1.32,1.32,0,0,1-.59.58,1.82,1.82,0,0,1,.68.49,5.52,5.52,0,0,1,.83,1.46l1,2.28h-1.91l-.91-2.39a3,3,0,0,0-.46-.92.76.76,0,0,0-.58-.21v3.52Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M427.38,159.94v-8.23h1.71v5.16l2.74-5.16h1.77v8.23h-1.72v-5.28l-2.79,5.28Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M445.38,158.13h.71v4h-1.44v-2.18h-5.12v2.18h-1.44v-4h.71c.7-1,1.07-3.13,1.11-6.42h5.47Zm-4.83,0h3v-4.61h-2.14A10.25,10.25,0,0,1,440.55,158.13Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M451.23,157.33l1.78.36a3.52,3.52,0,0,1-1.11,1.84,3.15,3.15,0,0,1-4.15-.4,5,5,0,0,1-1-3.26,5.27,5.27,0,0,1,1-3.31,2.58,2.58,0,0,1,2.12-1.05,2.73,2.73,0,0,1,2.28,1.07,5.88,5.88,0,0,1,.94,3.62v.25h-4.47a2.5,2.5,0,0,0,.44,1.52,1.25,1.25,0,0,0,1,.54C450.66,158.51,451,158.11,451.23,157.33Zm.1-2.21a2.45,2.45,0,0,0-.41-1.45,1.15,1.15,0,0,0-.93-.49,1.17,1.17,0,0,0-.95.51,2.32,2.32,0,0,0-.39,1.43Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M453.75,153.42v-1.71h6.12v1.71h-2.16v6.52h-1.79v-6.52Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M464.85,157.33l1.78.36a3.52,3.52,0,0,1-1.11,1.84,3.15,3.15,0,0,1-4.15-.4,5,5,0,0,1-1-3.26,5.21,5.21,0,0,1,1-3.31,2.58,2.58,0,0,1,2.12-1.05,2.73,2.73,0,0,1,2.28,1.07,5.88,5.88,0,0,1,.94,3.62v.25h-4.47a2.5,2.5,0,0,0,.44,1.52,1.25,1.25,0,0,0,1,.54C464.28,158.51,464.66,158.11,464.85,157.33Zm.1-2.21a2.45,2.45,0,0,0-.41-1.45,1.15,1.15,0,0,0-.93-.49,1.17,1.17,0,0,0-.95.51,2.32,2.32,0,0,0-.39,1.43Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M468.12,159.94v-8.23h1.72v5.16l2.74-5.16h1.77v8.23h-1.72v-5.28l-2.79,5.28Zm4.38-11.5h.87a2.54,2.54,0,0,1-.62,1.67,1.77,1.77,0,0,1-1.36.59,1.8,1.8,0,0,1-1.36-.59,2.64,2.64,0,0,1-.62-1.67h.87c.07.75.44,1.12,1.11,1.12S472.44,149.19,472.5,148.44Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M479.7,159.94v-8.23h1.71v5.16l2.74-5.16h1.77v8.23H484.2v-5.28l-2.79,5.28Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M497.25,154.13l-1.76.39c-.13-.86-.53-1.29-1.22-1.29a1.25,1.25,0,0,0-1.07.56,3.32,3.32,0,0,0-.4,1.89,3.91,3.91,0,0,0,.4,2.07,1.23,1.23,0,0,0,1.08.61,1.09,1.09,0,0,0,.83-.35,2.26,2.26,0,0,0,.47-1.22l1.76.37c-.36,2-1.41,3-3.13,3a2.71,2.71,0,0,1-2.45-1.26,5.5,5.5,0,0,1-.79-3,5,5,0,0,1,.9-3.23,3.17,3.17,0,0,1,4.31-.48A3.55,3.55,0,0,1,497.25,154.13Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M502.52,157.33l1.78.36a3.52,3.52,0,0,1-1.11,1.84,3.15,3.15,0,0,1-4.15-.4,5,5,0,0,1-1-3.26,5.21,5.21,0,0,1,1-3.31,2.58,2.58,0,0,1,2.12-1.05,2.73,2.73,0,0,1,2.28,1.07,5.88,5.88,0,0,1,.94,3.62v.25h-4.47a2.5,2.5,0,0,0,.44,1.52,1.25,1.25,0,0,0,1,.54C502,158.51,502.33,158.11,502.52,157.33Zm.1-2.21a2.45,2.45,0,0,0-.41-1.45,1.15,1.15,0,0,0-.93-.49,1.17,1.17,0,0,0-.95.51,2.32,2.32,0,0,0-.39,1.43Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M505.89,159.94v-8.23h2.22l1.67,5.73,1.7-5.73h2.21v8.23h-1.54v-5.42l-1.64,5.42H509l-1.55-5.42v5.42Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M519.4,157.33l1.77.36a3.52,3.52,0,0,1-1.11,1.84,2.84,2.84,0,0,1-1.83.6,2.79,2.79,0,0,1-2.31-1,5,5,0,0,1-1-3.26,5.27,5.27,0,0,1,1-3.31,2.59,2.59,0,0,1,2.13-1.05,2.72,2.72,0,0,1,2.27,1.07,5.88,5.88,0,0,1,.94,3.62v.25h-4.47a2.43,2.43,0,0,0,.43,1.52,1.29,1.29,0,0,0,1,.54C518.82,158.51,519.21,158.11,519.4,157.33Zm.09-2.21a2.38,2.38,0,0,0-.41-1.45,1.15,1.15,0,0,0-.92-.49,1.17,1.17,0,0,0-1,.51,2.25,2.25,0,0,0-.38,1.43Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
                <path
                  d="M522.67,159.94v-8.23h1.71v5.16l2.74-5.16h1.77v8.23h-1.72v-5.28l-2.79,5.28Zm4.38-11.5h.87a2.64,2.64,0,0,1-.62,1.67,1.86,1.86,0,0,1-2.72,0,2.65,2.65,0,0,1-.63-1.67h.87c.07.75.44,1.12,1.12,1.12S527,149.19,527.05,148.44Z"
                  transform="translate(-89.27 -69.86)"
                  class="cls-1"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>

  <div class="about-advantage" style="padding-top: 0">
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="0.3s"
      data-wow-offset="200"
      style="
        animation-delay: 0.3s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block social">
        <div>1.</div>
        Адресная помощь семьям
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="0.6s"
      data-wow-offset="200"
      style="
        animation-delay: 0.6s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block social">
        <div>2.</div>
        Помощь бездомным
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="0.9s"
      data-wow-offset="200"
      style="
        animation-delay: 0.9s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block social">
        <div>3.</div>
        Поддержка семей с ограниченными возможностями
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="1.2s"
      data-wow-offset="200"
      style="
        animation-delay: 1.2s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block social">
        <div>4.</div>
        Адаптация воспитанников детских домов
      </div>
    </div>
    <div
      class="col20 wow anim-visible advantage-hidden"
      data-wow-delay="1.5s"
      data-wow-offset="200"
      style="
        animation-delay: 1.5s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="advantage-block social">
        <div>5.</div>
        Сотрудничество с больницами и реабилитационными центрами
      </div>
    </div>
  </div>

  <div id="about-ceo" class="social">
    <div
      id="about-ceo-info"
      class="wow anim-visible"
      data-wow-delay="0.4s"
      data-wow-offset="300"
      style="
        animation-delay: 0.4s;
        animation-name: visible;
        visibility: visible;
      "
    >
      <div class="video-wrap">
        <div class="video-block">
          <iframe
            width="100%"
            src="https://www.youtube.com/embed/6uSPkxu1JZk"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
      <div>
        <div id="about-ceo-name">Руслан Романов</div>
        <div id="about-ceo-position">
          Подопечный Фонда Teresa по программе адаптации воспитанников детских
          домов.
        </div>
        <p>
          Руслан – выпускник детского дома, участник программы по адаптации
          воспитанников детских домов Фонда Teresa. Цель этой программы – дать
          детям-сиротам возможность получить контракт в одной из
          компаний-партнеров Фонда.
        </p>
        <p>
          Уже почти полгода Руслан работает в компании Делимобиль, где
          занимается тем, что рисует картины, делает мозаики для офиса и
          готовится к выставке. Молодой человек уже почувствовал уверенность в
          своих силах, обрел новых друзей и коллег, вырос творчески и
          профессионально и даже проводит собственные мастер-классы по рисованию
          и мозаике сотрудникам компании.
        </p>
      </div>
    </div>
  </div>
  <br /><br />

  <div class="company-history-awards">
    <div class="hclient-header" style="text-align: center">
      Помочь Фонду в развитии программ Вы можете уже сейчас
    </div>
    <br /><br />
    <div class="hclient-header" style="text-align: center">
      <a
        href="https://teresafund.ru/"
        style="color: black"
        target="_blank"
        rel="nofollow"
        >teresafund.ru</a
      >
    </div>
  </div>

  <app-footer></app-footer>
</div>
